import React from "react";
import { SemanticDataTable } from "../../../../../../../shared/ui";
import { isBlank, numberComparator } from "@sempra-event-registration/common";
import { Icon } from "semantic-ui-react";
import classes from "./InviteeTable.module.css";

function InviteeTable({
  rows,
  rowOptions,
  onRowClick = () => {},
  showErrors,
  ...rest
}) {
  const columns = [];

  if (rows.some((x) => x.lineNumber != null)) {
    columns.push({
      id: "lineNumber",
      title: "Line",
      sortable: true,
      bodyClassName: classes.minWidth,
      comparator: numberComparator,
      value: (x) => x.lineNumber,
      format: (value) => (value ? String(value) : ""),
    });
  }

  columns.push({
    id: "email",
    title: "Email",
    sortable: true,
    cellProps: () => ({ collapsing: true }),
    value: (x) => x.email?.toLowerCase() || "",
    format: (value, x) => {
      const errors = (x.errors || []).filter(({ field }) => field === "email");
      if (errors.length > 0) {
        return (
          <span>
            <Icon name="attention" />
            {value}
          </span>
        );
      }
      return value;
    },
  });

  if (rows.some((x) => !isBlank(x.firstName))) {
    columns.push({
      id: "firstName",
      title: "First Name",
      sortable: true,
      value: (x) => x.firstName || "",
    });
  }

  if (rows.some((x) => !isBlank(x.lastName))) {
    columns.push({
      id: "lastName",
      title: "Last Name",
      sortable: true,
      value: (x) => x.lastName || "",
    });
  }

  if (showErrors) {
    columns.push({
      id: "errors",
      title: "Errors",
      sortable: true,
      sortableValue: (x) => x.errors.map((x) => x.message).join(""),
      format: (x) => (
        <>
          {x.errors.map((error, index) => (
            <div key={index}>{error.message}.</div>
          ))}
        </>
      ),
    });
  }

  return (
    <SemanticDataTable
      className={classes.table}
      singleLine
      striped
      searchEnabled={false}
      compact={true}
      {...{
        columns,
        rows,
        rowOptions,
        onRowClick,
        rowProps: (x) => ({
          error: showErrors && x.errors != null,
        }),
        ...rest,
      }}
    />
  );
}

export default InviteeTable;
