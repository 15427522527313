import React from "react";
import { Form } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

function CheckboxList({ options, name, rules, ...rest }) {
  const { control, setValue, watch } = useFormContext();
  const selectedOptions = watch(name);
  const setValueOptions = {
    shouldDirty: true,
    shouldValidate: true,
  };
  const { className } = rest;

  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        return (
          <>
            <input type="hidden" {...props} />
            <ul className={className}>
              {options.map((option) => {
                return (
                  <li key={option.value}>
                    <Form.Checkbox
                      checked={selectedOptions.includes(option.value)}
                      label={option.label}
                      onChange={(e, { checked }) => {
                        // If we don't already have the selected option in our list of
                        // selected roles, add it.  Otherwise, remove it.
                        if (checked) {
                          if (!selectedOptions.includes(option.value)) {
                            setValue(
                              name,
                              selectedOptions.concat(option.value),
                              setValueOptions
                            );
                          }
                        } else {
                          setValue(
                            name,
                            selectedOptions.filter((x) => x !== option.value),
                            setValueOptions
                          );
                        }
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        );
      }}
      rules={rules}
    />
  );
}

export default CheckboxList;
