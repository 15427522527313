import React from "react";
import EventDetailsSection from "../component/EventForm/section/EventDetailsSection/EventDetailsSection";
import EventDateAndTimeSection from "../component/EventForm/section/EventDateAndTimeSection/EventDateAndTimeSection";
import EventLocationSection from "../component/EventForm/section/EventLocationSection/EventLocationSection";
import EventContactsSection from "../component/EventForm/section/EventContactsSection/EventContactsSection";
import EventMealsSection from "../component/EventForm/section/EventMealsSection/EventMealsSection";
import EventGroupsSection from "../component/EventForm/section/EventGroupsSection/EventGroupsSection";
import EventDocumentsSection from "../component/EventForm/section/EventDocumentsSection/EventDocumentsSection";
import EventWorkshopSection from "../component/EventForm/section/EventWorkshopSection/EventWorkshopSection";
import EventMessagesSection from "../component/EventForm/section/EventMessagesSection/EventMessagesSection";

const formSectionsById = [
  {
    id: "event-details",
    title: "Event Details",
    component: (props) => <EventDetailsSection {...props} />,
  },
  {
    id: "date-and-time",
    title: "Date & Time",
    component: (props) => <EventDateAndTimeSection {...props} />,
  },
  {
    id: "location",
    title: "Location",
    component: (props) => <EventLocationSection {...props} />,
  },
  {
    id: "contact",
    title: "Contact",
    component: (props) => <EventContactsSection {...props} />,
  },
  {
    id: "meals",
    title: "Meals",
    component: (props) => <EventMealsSection {...props} />,
  },
  {
    id: "groups",
    title: "Groups",
    component: (props) => <EventGroupsSection {...props} />,
  },
  {
    id: "documents",
    title: "Documents",
    component: (props) => <EventDocumentsSection {...props} />,
  },
  {
    id: "workshops",
    title: "Workshops",
    component: (props) => <EventWorkshopSection {...props} />,
  },
  {
    id: "messages",
    title: "Custom Email Messages",
    component: (props) => <EventMessagesSection {...props} />,
  },
].reduce(
  (byId, section) => ({
    ...byId,
    [section.id]: section,
  }),
  {}
);

const commonStartSections = [
  "event-details",
  "date-and-time",
  "location",
  "contact",
  "meals",
];

const commonEndSections = ["documents", "messages"];

/**
 * A collection of all possible "sections" (i.e. screens/forms) that a user needs to fill out while creating an event.
 */
const formSectionIdsByEventType = {
  sdge_external: [...commonStartSections, ...commonEndSections],
  internal: [
    ...commonStartSections,
    "workshops",
    "groups",
    ...commonEndSections,
  ],
  sdge_employee: [...commonStartSections, ...commonEndSections],
};

const formSectionsByType = Object.entries(formSectionIdsByEventType).reduce(
  (byType, [type, sectionIds]) => ({
    ...byType,
    [type]: sectionIds.map((sectionId) => formSectionsById[sectionId]),
  }),
  {}
);

export function getEventFormSections(event) {
  return formSectionsByType[event.type].filter(
    (section) => section.enabled == null || section.enabled(event)
  );
}
