import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import EventVirtualMeetingLink from "../../field/EventVirtualMeetingLink/EventVirtualMeetingLink";
import EventLocationName from "../../field/EventLocationName/EventLocationName";
import EventLocationCapacity from "../../field/EventLocationCapacity/EventLocationCapacity";
import Input from "../../../../../../../shared/react-hook-form/component/Input/Input";
import EventLocationAddress from "../../field/EventLocationAddress/EventLocationAddress";
import EventRoom from "../../field/EventRoom/EventRoom";
import EventLocationAdditionalDirections from "../../field/EventLocationAdditionalDirections/EventLocationAdditionalDirections";
import EventLocationCity from "../../field/EventLocationCity/EventLocationCity";
import EventLocationZip from "../../field/EventLocationZip/EventLocationZip";
import EventLocationState from "../../field/EventLocationState/EventLocationState";
import { locationToFormValues } from "../../../../support/eventForms";
import { FieldTree } from "../../../../../../../shared/react-hook-form";
import FieldContainer from "../../component/FieldContainer/FieldContainer";
import classes from "./EventLocationSection.module.css";

function EventLocationSection({
  event,
  fieldProps,
  additionalDirections,
  setAdditionalDirections,
}) {
  const { register, setValue, watch } = useFormContext();

  function fill(field, value) {
    if (value != null) {
      setValue(field, value, { shouldValidate: true, shouldDirty: true });
    }
  }

  function fillAddress(address) {
    const { city, state, zipCode, latitude, longitude, googlePlaceId } =
      address;
    fill("city", city);
    fill("state", state);
    fill("zipCode", zipCode);
    // TODO if anything about the address is manually changed
    //  after selecting a suggested address these values need to clear out
    setValue("latitude", latitude);
    setValue("longitude", longitude);
    setValue("googlePlaceId", googlePlaceId);
  }

  function fillLocation(location) {
    const values = locationToFormValues(location);
    const {
      locationName,
      addressLine1,
      addressLine2,
      additionalDirections,
      capacity,
    } = values;
    fill("locationName", locationName);
    fill("addressLine1", addressLine1);
    fill("addressLine2", addressLine2);
    fillAddress(values);
    if (additionalDirections.getCurrentContent().hasText()) {
      fill("additionalDirections", additionalDirections);
    }
    if (!isNaN(parseInt(capacity))) {
      fill("capacity", capacity);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLocationChange = useCallback(fillLocation, [setValue]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddressSelect = useCallback(fillAddress, [setValue]);

  const roomField = {
    name: "rooms",
    label: "Room",
    limit: 20,
    render: (props) => <EventRoom {...{ ...fieldProps, ...props }} />,
    empty: () => ({ name: "", capacity: "" }),
  };

  return (
    <>
      {/* Hidden values for google place returned values */}
      <input type="hidden" {...register("latitude")} name="latitude" />
      <input type="hidden" {...register("longitude")} name="longitude" />
      <input
        type="hidden"
        {...register("googlePlaceId")}
        name="googlePlaceId"
      />

      <FieldContainer>
        <EventVirtualMeetingLink {...{ ...fieldProps, setValue }} />
        {!watch("isVirtualEvent") ? (
          <>
            <EventLocationName
              {...{
                onLocationChange,
                ...fieldProps,
                className: classes.eventLocationName,
              }}
            />
            <EventLocationAddress onSelect={onAddressSelect} {...fieldProps} />
            <Input
              name="addressLine2"
              label="Address Line 2"
              autoComplete="off"
              placeholder="Unit or Apt #"
              {...fieldProps}
            />
            <div className={classes.addressGrid}>
              <EventLocationCity {...fieldProps} />
              <EventLocationState {...fieldProps} />
              <EventLocationZip {...fieldProps} />
            </div>
          </>
        ) : (
          <div>
            {event.virtualEventLink ? (
              <a href={event.virtualEventLink}>Link to Virtual Event</a>
            ) : (
              "Link will be generated on published events after save"
            )}
          </div>
        )}
        <EventLocationCapacity {...fieldProps} />
      </FieldContainer>
      {!watch("isVirtualEvent") && (
        <>
          <EventLocationAdditionalDirections
            {...{
              ...fieldProps,
              additionalDirections,
              setAdditionalDirections,
            }}
          />
        </>
      )}
      {event.type === "internal" && (
        <FieldTree field={roomField} {...fieldProps} />
      )}
    </>
  );
}

export default EventLocationSection;
