import React from "react";
import {
  Checkbox,
  Select,
} from "../../../../../../../../../shared/react-hook-form";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { isBlank } from "@sempra-event-registration/common";
import classes from "./RegistrationMeals.module.css";

function RegistrationMealOption({ event, index, ...rest }) {
  const { watch } = useFormContext();
  const selected = watch(`meals[${index}].selected`);
  const options = event.meals[index].options || [];
  const hidden = !selected || options.length === 0;

  return (
    <Select
      className={`${hidden ? classes.hidden : ""} ${classes.option}`}
      {...rest}
      options={options.map((option) => ({
        key: option.id,
        text:
          option.name +
          (!isBlank(option.dietaryType) ? ` (${option.dietaryType})` : ""),
        value: option.id,
      }))}
    />
  );
}

function RegistrationMeals({ disabled, event, className, ...rest }) {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: "meals",
  });

  return (
    <div className={`${className || ""} ${classes.grid}`} {...rest}>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Controller
            control={control}
            name={`meals[${index}].id`}
            defaultValue={field.id}
            render={(props) => <input type="hidden" {...props} />}
          />
          <Checkbox
            {...{ control, disabled }}
            id={`meal-${field.id}-selected`}
            name={`meals[${index}].selected`}
            label={event.meals[index].name}
            defaultValue={field.selected}
          />
          {(event.meals[index].options || []).length > 0 && (
            <RegistrationMealOption
              {...{ event, disabled, index }}
              id={`meal-${field.id}-option`}
              name={`meals[${index}].option`}
              defaultValue={field.option}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default RegistrationMeals;
