import React from "react";
import { Label } from "semantic-ui-react";
import classes from "./RegistrationStatusBadge.module.css";
import { registrationStatusNameById } from "@sempra-event-registration/common/src/event";

function RegistrationStatusBadge({ status }) {
  return (
    <Label className={classes[status]}>
      {registrationStatusNameById[status]}
    </Label>
  );
}

export default RegistrationStatusBadge;
