import { startOfDay, endOfDay } from "date-fns";
import { timeOf } from "../date";
import isEqual from "lodash.isequal";

export function isTruthy(value) {
  if (typeof value === "number") {
    return !isNaN(value);
  }
  if (typeof value === "string") {
    return value.trim().length > 0;
  }
  return !!value;
}

/**
 * Creates a copy of the object that is recursively normalized for processing with recursive deep-equals
 *
 * This method coerces null, false and blank strings into undefined for comparisons
 *
 * @param value The object to recursively normalize
 * @return {Object} The normalized object
 */
export function normalize(value) {
  if (!isTruthy(value)) {
    return undefined;
  }
  if (typeof value === "string") {
    return value.trim();
  }
  if (Array.isArray(value)) {
    return value.map(normalize);
  }
  if (typeof value === "object" && !(value instanceof Date)) {
    return Object.entries(value).reduce((mapped, [key, value]) => {
      const normalizedValue = normalize(value);
      if (isTruthy(normalizedValue)) {
        mapped[key] = normalizedValue;
      }
      return mapped;
    }, {});
  }
  return value;
}

// not used here TODO move package
export function equals(a, b) {
  return isEqual(normalize(a), normalize(b));
}

/**
 * Extracts event fields that are worthy of notification if they change as they could cause registrants to update
 * their plans, cancel or change their registration.
 *
 * @param value The event to get the fields from
 * @return {Object} The extracted fields
 */
function notificationWorthyEventFields(value) {
  const {
    name,
    description,
    date,
    startsAt,
    startTime,
    endTime,
    registrationDeadline,
    location: { rooms, capacity, ...location } = {},
    documents = [],
    groups: eventGroups = [],
    meals: eventMeals = [],
    workshops: eventWorkshops = [],
  } = value;

  const groups = eventGroups.reduce(
    (flattenedGroups, group) => [
      ...flattenedGroups,
      ...((group.subgroups || []).length === 0
        ? [group].map((x) => ({
            id: x.id,
            name: group.name,
          }))
        : group.subgroups.map((x) => {
            // remove circular subgroup references
            const { subgroups, ...prunedGroup } = group;
            return {
              // don't need qualified ID here because IDs are uuid
              id: x.id,
              name: group.name,
              group: prunedGroup,
            };
          })),
    ],
    []
  );

  const meals = eventMeals.map((x) => ({
    id: x.id,
    name: x.name,
    startTime: timeOf(x.startTime || x.startsAt),
    endTime: timeOf(x.endTime || x.endsAt), // TODO change to startTime endTime
  }));

  const mealOptions = eventMeals.reduce(
    (options, meal) => [
      ...options,
      ...(meal.options || []).map((option) => ({
        id: option.id,
        name: option.name,
        dietaryType: option.dietaryType,
        // get meal that doesn't hold circular options references
        meal: meals.find((x) => x.id === meal.id),
      })),
    ],
    []
  );

  const workshops = eventWorkshops.map((x) => {
    const { id, name, room, startTime, endTime } = x;
    return {
      id,
      name,
      room: {
        id: room.id,
        name: room.name,
      },
      startTime: timeOf(startTime),
      endTime: timeOf(endTime),
    };
  });

  return {
    name,
    // not able to run convertToRaw(description.getCurrentContent())
    description,
    date: startOfDay(date || startsAt),
    startTime: timeOf(startTime),
    endTime: timeOf(endTime),
    registrationDeadline: endOfDay(registrationDeadline),
    location,
    meals,
    mealOptions,
    documents,
    groups,
    workshops,
  };
}

/**
 * Produces a list of notification-worthy event differences
 *
 * Changes that are tracked:
 * name change
 * description change
 * date change
 * start time change
 * end time change
 * registration deadline shortening
 * location change
 * document additions
 * group removals -> prompt change of registration
 * meal removals/time changes -> prompt change of registration
 * workshop removals/time changes
 *
 * @param a The first event
 * @param b The second event
 * @return {[]} The differences
 */
export function notificationWorthyEventChanges(a, b) {
  const changes = [];
  const before = normalize(notificationWorthyEventFields(a));
  const after = normalize(notificationWorthyEventFields(b));

  // Name & Description

  if (!isEqual(before.name, after.name)) {
    changes.push({
      type: "change",
      path: ["name"],
      before: before.name,
      after: after.name,
    });
  }

  if (!isEqual(before.description, after.description)) {
    changes.push({
      type: "change",
      path: ["description"],
      before: before.description,
      after: after.description,
    });
  }

  // Date & Times

  if (!isEqual(before.date, after.date)) {
    changes.push({
      type: "change",
      path: ["date"],
      before: before.date,
      after: after.date,
    });
  }

  if (!isEqual(before.startTime, after.startTime)) {
    changes.push({
      type: "change",
      path: ["startTime"],
      before: before.startTime,
      after: after.startTime,
    });
  }

  if (!isEqual(before.endTime, after.endTime)) {
    changes.push({
      type: "change",
      path: ["endTime"],
      before: before.endTime,
      after: after.endTime,
    });
  }

  if (!isEqual(before.registrationDeadline, after.registrationDeadline)) {
    changes.push({
      type: "change",
      path: ["registrationDeadline"],
      before: before.registrationDeadline,
      after: after.registrationDeadline,
    });
  }

  if (!isEqual(before.location, after.location)) {
    changes.push({
      type: "change",
      path: ["location"],
      before: before.location,
      after: after.location,
    });
  }

  // Documents

  const addedDocuments = after.documents.filter(
    (x) => !before.documents.some((y) => y.id === x.id)
  );
  if (addedDocuments.length > 0) {
    changes.push({
      type: "add",
      path: ["documents"],
      before: before.documents,
      after: after.documents,
      difference: addedDocuments,
    });
  }

  // Groups

  const removedGroups = before.groups.filter(
    (x) => !after.groups.some((y) => y.id === x.id)
  );
  if (removedGroups.length > 0) {
    changes.push({
      type: "remove",
      path: ["groups"],
      before: before.groups,
      after: after.groups,
      difference: removedGroups,
    });
  }

  // Meals

  const removedMeals = before.meals.filter(
    (x) => !after.meals.some((y) => y.id === x.id)
  );
  if (removedMeals.length > 0) {
    changes.push({
      type: "remove",
      path: ["meals"],
      before: before.meals,
      after: after.meals,
      difference: removedMeals,
    });
  }

  const changedMeals = after.meals.reduce((changes, mealAfter) => {
    const mealBefore = before.meals.find((x) => x.id === mealAfter.id);
    if (mealBefore != null && !isEqual(mealBefore, mealAfter)) {
      changes.push({
        before: mealBefore,
        after: mealAfter,
      });
    }
    return changes;
  }, []);
  if (changedMeals.length > 0) {
    changes.push({
      type: "change",
      path: ["meals"],
      before: before.meals,
      after: after.meals,
      differences: changedMeals,
    });
  }

  // Meal options

  const removedMealOptions = before.mealOptions.filter(
    (x) => !after.mealOptions.some((y) => y.id === x.id)
  );
  if (removedMealOptions.length > 0) {
    changes.push({
      type: "remove",
      path: ["mealOptions"],
      before: before.mealOptions,
      after: after.mealOptions,
      difference: removedMealOptions,
    });
  }
  const changedMealOptions = after.mealOptions.reduce(
    (changes, mealOptionAfter) => {
      const mealOptionBefore = before.mealOptions.find(
        (x) => x.id === mealOptionAfter.id
      );
      // remove parent ref for comparison
      const { meal: mealA, ...a } = mealOptionBefore || {};
      const { meal: mealB, ...b } = mealOptionAfter;
      if (mealOptionBefore != null && !isEqual(a, b)) {
        changes.push({
          before: mealOptionBefore,
          after: mealOptionAfter,
        });
      }
      return changes;
    },
    []
  );
  if (changedMealOptions.length > 0) {
    changes.push({
      type: "change",
      path: ["mealOptions"],
      before: before.mealOptions,
      after: after.mealOptions,
      differences: changedMealOptions,
    });
  }

  // Workshops

  const removedWorkshops = before.workshops.filter(
    (x) => !after.workshops.some((y) => y.id === x.id)
  );
  if (removedWorkshops.length > 0) {
    changes.push({
      type: "remove",
      path: ["workshops"],
      before: before.workshops,
      after: after.workshops,
      difference: removedWorkshops,
    });
  }

  const changedWorkshops = after.workshops.reduce((changes, workshopAfter) => {
    const workshopBefore = before.workshops.find(
      (x) => x.id === workshopAfter.id
    );
    if (workshopBefore != null && !isEqual(workshopBefore, workshopAfter)) {
      changes.push({
        before: workshopBefore,
        after: workshopAfter,
      });
    }
    return changes;
  }, []);
  if (changedWorkshops.length > 0) {
    changes.push({
      type: "change",
      path: ["workshops"],
      before: before.workshops,
      after: after.workshops,
      differences: changedWorkshops,
    });
  }

  return changes;
}

export function notificationWorthyEventChangesAffectingRegistrant(
  changes,
  registration
) {
  return changes.reduce((relevantChanges, change) => {
    const {
      type,
      path: [field],
      difference,
      differences,
    } = change;
    const { group, subgroup, meals = [], workshops = [] } = registration;
    switch (`${field}.${type}`) {
      case "meals.remove":
        const removedMeals = difference.filter((x) =>
          meals.some((y) => x.id === y.id)
        );
        if (removedMeals.length > 0) {
          relevantChanges.push({
            ...change,
            difference: removedMeals,
          });
        }
        break;
      case "meals.change":
        const changedMeals = differences.filter((x) =>
          meals.some((y) => x.before.id === y.id)
        );
        if (changedMeals.length > 0) {
          relevantChanges.push({
            ...change,
            differences: changedMeals,
          });
        }
        break;
      case "mealOptions.remove":
        const removedMealOptions = difference.filter((x) => {
          const meal = meals.find((y) => x.meal.id === y.id);
          return meal != null && meal.option != null && meal.option.id === x.id;
        });
        if (removedMealOptions.length > 0) {
          relevantChanges.push({
            ...change,
            difference: removedMealOptions,
          });
        }
        break;
      case "mealOptions.change":
        const changedMealOptions = differences.filter((x) => {
          const meal = meals.find((y) => x.before.meal.id === y.id);
          return (
            meal != null &&
            meal.option != null &&
            meal.option.id === x.before.id
          );
        });
        if (changedMealOptions.length > 0) {
          relevantChanges.push({
            ...change,
            differences: changedMealOptions,
          });
        }
        break;
      case "workshops.remove":
        const removedWorkshops = difference.filter((x) =>
          workshops.some((y) => x.id === y.id)
        );
        if (removedWorkshops.length > 0) {
          relevantChanges.push({
            ...change,
            difference: removedWorkshops,
          });
        }
        break;
      case "workshops.change":
        const changedWorkshops = differences.filter((x) =>
          workshops.some((y) => x.before.id === y.id)
        );
        if (changedWorkshops.length > 0) {
          relevantChanges.push({
            ...change,
            differences: changedWorkshops,
          });
        }
        break;
      case "groups.remove":
        const removedGroups = difference.filter(
          (x) =>
            (group && group.id === x.id) || (subgroup && subgroup.id === x.id)
        );
        if (removedGroups.length > 0) {
          relevantChanges.push({
            ...change,
            difference: removedGroups,
          });
        }
        break;
      default:
        relevantChanges.push(change);
    }
    return relevantChanges;
  }, []);
}
