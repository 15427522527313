import React from "react";
import { Editor, RichUtils, EditorState } from "draft-js";
import styles from "./RichTextEditor.module.css";
import EmphasisButtonGroup from "./controls/EmphasisButtonGroup";
import FontSizeSelect from "./controls/FontSizeSelect";
import ListTypeButtonGroup from "./controls/ListTypeButtonGroup";
import { Container } from "semantic-ui-react";

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.toggleBlockType = (e, type) => this._toggleBlockType(e, type);
    this.toggleInlineStyle = (e, style) => this._toggleInlineStyle(e, style);
  }

  _handleKeyCommand(command) {
    const { value, onChange } = this.props;
    const newState = RichUtils.handleKeyCommand(value, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  }

  _toggleBlockType(e, blockType) {
    e.preventDefault();

    const { value, onChange } = this.props;

    // We need to do a forceSelection here, otherwise the editor text area losses focus, meaning the user can't type
    // anymore unless they click in the editor's text area again.  This is an open issue against DraftJS:
    // https://github.com/facebook/draft-js/issues/696.
    const editorStateFocused = EditorState.forceSelection(
      value,
      value.getSelection()
    );

    // When the blockType is set via the Semantic UI dropdown, get the value from the object that is sent.  Otherwise,
    // the blockType is already a string, so it can be used directly.
    onChange(
      RichUtils.toggleBlockType(
        editorStateFocused,
        blockType.value || blockType
      )
    );
  }

  _toggleInlineStyle(e, inlineStyle) {
    e.preventDefault();
    const { value, onChange } = this.props;

    // We need to do a forceSelection here, otherwise the editor text area losses focus, meaning the user can't type
    // anymore unless they click in the editor's text area again.  This is an open issue against DraftJS:
    // https://github.com/facebook/draft-js/issues/696.
    const editorStateFocused = EditorState.forceSelection(
      value,
      value.getSelection()
    );

    onChange(RichUtils.toggleInlineStyle(editorStateFocused, inlineStyle));
  }

  // Custom overrides for "code" style.
  styleMap = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  render() {
    const { placeholder, value, onChange, disabled, className } = this.props;
    let richEditorStyles = styles.editor;
    const contentState = value.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        richEditorStyles += ` ${styles.hidePlaceholder}`;
      }
    }

    return (
      <div
        className={`${styles.root} ${className}`}
        data-testid="richtextEditor"
      >
        <Container fluid className={styles.controls}>
          <div className={styles.fontSizeDropdownControlsSection}>
            <FontSizeSelect
              editorState={value}
              onSelectionChange={this.toggleBlockType}
            />
          </div>
          <div className={styles.controlsSection}>
            <EmphasisButtonGroup
              editorState={value}
              onButtonClick={this.toggleInlineStyle}
            />
          </div>
          <div className={styles.controlsSection}>
            <ListTypeButtonGroup
              editorState={value}
              onButtonClick={this.toggleBlockType}
            />
          </div>
          {this.props.additionalControls &&
            this.props.additionalControls.map((control) => {
              return (
                <div
                  className={`${styles.controlsSection} ${styles.additionalControls}`}
                  key={control.key}
                >
                  {control}
                </div>
              );
            })}
        </Container>
        <div className={richEditorStyles}>
          <Editor
            {...{ onChange, placeholder }}
            blockStyleFn={getBlockStyle}
            customStyleMap={this.styleMap}
            editorState={value}
            handleKeyCommand={this.handleKeyCommand}
            spellCheck
            readOnly={disabled}
          />
        </div>
      </div>
    );
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "blockquote";
    default:
      return null;
  }
}

RichTextEditor.defaultProps = {
  value: EditorState.createEmpty(),
};

export default RichTextEditor;
