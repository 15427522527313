import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Image } from "semantic-ui-react";
import classes from "./UserMenu.module.css";

const optionsFactory = (navigate) => [
  {
    key: "account-settings",
    icon: "id card outline",
    text: "Account Settings",
    onClick: () => window.open("https://myaccount.microsoft.com/", "_blank"),
  },
  {
    key: "sign-out",
    icon: "sign-out",
    text: "Sign Out",
    onClick: async () => {
      navigate("/signout");
    },
  },
];

function UserMenu({ user }) {
  const navigate = useNavigate();
  const { email, firstName, photoUrl } = user;
  const displayName = firstName || email;

  const trigger = useMemo(
    () => (
      <span className={classes.userMenuTrigger}>
        <span>Hi {displayName}</span>
        {photoUrl && <Image src={photoUrl} alt={displayName} avatar />}
      </span>
    ),
    [displayName, photoUrl],
  );

  // show the avatar in place of the down caret if present
  const icon = photoUrl ? null : "caret down";
  const options = optionsFactory(navigate);

  return <Dropdown pointing="top right" {...{ trigger, icon, options }} />;
}

export default UserMenu;
