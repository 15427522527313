import {
  comparing,
  composeComparators,
  dateComparator,
  isBlank,
  nullsLast,
  numberComparator,
  reverse,
  stringComparator,
} from "@sempra-event-registration/common";

function normalizeSearch(value) {
  return (value || "").trim().toLowerCase();
}

// Filter based on search input
export const searchFilter = function searchFilter(columns, search) {
  search = normalizeSearch(search);
  return (row) =>
    isBlank(search) ||
    columns.some((column) => {
      const value = column.value ? column.value(row) : row;
      if (value == null) {
        return false;
      }
      const format = column.searchableValue || column.format || ((v) => v);
      const formattedContent = format(value);
      const searchableContent =
        typeof formattedContent === "string" ? formattedContent : String(value);

      return normalizeSearch(searchableContent).includes(search);
    });
};

const comparatorsByDataType = {
  number: numberComparator,
  string: stringComparator,
  date: dateComparator,
};

// sorting rows in table function
export function rowComparator(columnIdsAndDirections, columns) {
  const comparator = composeComparators(
    ...columnIdsAndDirections.map(([columnId, direction = "ascending"]) => {
      const sortColumn = columns.find((x) => x.id === columnId);

      // If an explicit comparator is provided it will be used
      // If absent, the comparator for the column's explicit data type will be used
      // If absent, the default null-safe string comparator will be used
      let comparator =
        sortColumn.comparator ||
        nullsLast(
          comparatorsByDataType[sortColumn.dataType] || stringComparator
        );
      // If the column specifies a value accessor use it to get the values before sorting
      const valueToSort = sortColumn.sortableValue || sortColumn.value;
      if (valueToSort) {
        comparator = comparing(comparator, valueToSort);
      }
      // If the configured sort direction is descending, reverse the natural sort order of ascending
      if (direction === "descending") {
        comparator = reverse(comparator);
      }
      return comparator;
    })
  );

  return (a, b) => {
    try {
      return comparator(a, b);
    } catch (error) {
      console.warn(`Failed to compare`, { a, b }, error);
    }
  };
}

export const prepDataForCSV = function (columns, rows) {
  return rows.map((row) => {
    let newRow = {};
    // TODO:  Add configuration to allow for using id or title as the column header
    columns.forEach(({ id, title, csvValue = (v) => v[id] }) => {
      // Use title if available, otherwise fall back to using the column's id.
      newRow[title || id] = csvValue(row);
    });
    return newRow;
  });
};
