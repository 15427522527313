import {
  collection,
  firestoreCollectionQuery,
  doc,
  firestore,
} from "@sempra-event-registration/common";

function documentToRegistrant(document) {
  const data = document.data();
  return {
    ...data,
    id: document.id,
  };
}

export const useEventRegistrants = firestoreCollectionQuery(
  (user, eventId) =>
    collection(doc(firestore, "events", eventId), "registrants"),
)
  // Don't bother calling this for new events that have no registrants
  .condition((user, eventId) => eventId != null)
  .map((snapshot) => snapshot.docs.map(documentToRegistrant))
  .build();
