import React from "react";
import { Controller } from "react-hook-form";
import { email, requiredNotBlank } from "@sempra-event-registration/common";
import { FormField } from "../../../shared/semantic-form";
import { selectError } from "../../../shared/form/support";
import InternalResourceTypeahead from "../../../event/page/EventPage/page/EventRegistrationPage/component/InternalResourceTypeahead/InternalResourceTypeahead";

function field(selector = (value) => value, validator) {
  return (value) => validator(selector(value));
}

function AdminUserTypeahead({
  control,
  errors,
  loading,
  onChange,
  userIsAlreadyAdminValidator,
}) {
  return (
    <Controller
      control={control}
      name="id"
      rules={{
        validate: {
          required: field((x) => x, requiredNotBlank()),
          email: field((x) => x, email()),
          userIsAlreadyAdmin: field((x) => x, userIsAlreadyAdminValidator),
        },
      }}
      render={({ field: { value, onChange: onChangeFromReactHookForm } }) => {
        return (
          <FormField
            label="User name or email address"
            error={selectError(() => errors.id)}
          >
            <InternalResourceTypeahead
              placeholder="Find someone by name or email"
              icon="search"
              loading={loading}
              search={value}
              onChange={(value) => {
                onChangeFromReactHookForm(value.id);
                onChange(value);
              }}
              onSearchChange={(search) => {
                onChangeFromReactHookForm(search);
              }}
            />
          </FormField>
        );
      }}
    />
  );
}

export default AdminUserTypeahead;
