import { isRunningLocally } from "./isRunningLocally";

const firestoreConsoleBaseUrl =
  "https://console.firebase.google.com/project/sempra-event-registration-dev/firestore/data~2F";

export function viewDataOption(path) {
  return {
    key: "view-data",
    text: "View Data",
    as: "a",
    href: firestoreConsoleBaseUrl + path.replace("/", "~2F"),
    target: "_blank",
    enabled: () => isRunningLocally(),
  };
}
