import { endOfToday, setHours, setMinutes } from "date-fns";

export const defaultNameMaxLength = 100;
export const defaultEventMealNameMaxLength = 30;
export const defaultEventMinTime = setHours(setMinutes(new Date(), 0), 5);
export const defaultEventMaxTime = endOfToday();
export const defaultEventDurationInMinutes = 30;
export const defaultEventDurationInHours = 1;
export const defaultEventMealDurationInMinutes = 30;
export const defaultEventWorkshopDurationInMinutes = 15;
export const minimumEventDurationInMinutes = 15;
