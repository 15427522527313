import React from "react";
import {
  eventTypeNamesByType,
  withBreadcrumbs,
  eventTypes,
  firestore,
  doc,
  getDocs,
} from "@sempra-event-registration/common";
import { Breadcrumb } from "semantic-ui-react";
import { useSearchRestoringHistory } from "../../../router";

function Breadcrumbs({ breadcrumbs }) {
  const history = useSearchRestoringHistory();
  return (
    <Breadcrumb>
      {(breadcrumbs || []).map(({ name, url }, index) => {
        const last = index === breadcrumbs.length - 1;
        const onClick = last
          ? null
          : (event) => {
              event.preventDefault();
              history.push(url);
            };
        return (
          <React.Fragment key={index}>
            <Breadcrumb.Section active={last} onClick={onClick}>
              {last ? <h1>{name}</h1> : name}
            </Breadcrumb.Section>
            {last || <Breadcrumb.Divider />}
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
}

export default withBreadcrumbs(Breadcrumbs, {
  defaultRoute: "/events",
  documentTitle: (breadcrumbs) =>
    ["Event Administration", ...breadcrumbs.map(({ name }) => name)].join(
      " > "
    ),
  resolvers: {
    "": "Home",
    admins: "Admins",
    events: "Events",
    ":eventId": async(id) => {
      if (eventTypes.includes(id)) {
        return `New ${eventTypeNamesByType[id]} Event`;
      }
      const docRef = doc(firestore, "events", id)
      const snapshot = await getDocs(docRef);
      return snapshot.data()?.name
    },
    registration: "Registration",
    reports: "Reports",
  },
});
