import React from "react";
import classes from "./FieldContainer.module.css";

function FieldContainer({ className, children, ...rest }) {
  return (
    <div className={`${className || ""} ${classes.container}`} {...rest}>
      {children}
    </div>
  );
}

export default FieldContainer;
