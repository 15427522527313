import React from "react";
import { Input } from "../../../../../../../../../shared/react-hook-form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import { selectError } from "../../../../../../../../../shared/form";
import classes from "./EventGroupOption.module.css";

function EventGroupOption({ index, field, namePrefix, errors, ...fieldProps }) {
  return (
    <div className={classes.grid}>
      <Input
        name={namePrefix + "registrationCount"}
        type="hidden"
        defaultValue={field.registrationCount || 0}
        {...fieldProps}
      />
      <Input
        name={namePrefix + "name"}
        label={`Subgroup ${index + 1}`}
        error={selectError(() => errors.name)}
        rules={{
          validate: requiredNotBlank("Required"),
        }}
        defaultValue={field.name || ""}
        {...fieldProps}
      />
      <Input
        name={namePrefix + "capacity"}
        label="Max Size"
        type="number"
        allowNegative={false}
        decimalScale={0}
        rules={{
          min: {
            value: 1,
            message: "Please enter a number greater than zero",
          },
        }}
        error={selectError(() => errors.capacity)}
        defaultValue={field.capacity || 0}
        {...fieldProps}
      />
      <Input
        name={namePrefix + "supervisor"}
        label="Supervisor"
        error={selectError(() => errors.supervisor)}
        defaultValue={field.supervisor || ""}
        {...fieldProps}
      />
      <Input
        name={namePrefix + "mailLocation"}
        label="Mail Location"
        error={selectError(() => errors.mailLocation)}
        defaultValue={field.mailLocation || ""}
        {...fieldProps}
      />
    </div>
  );
}

export default EventGroupOption;
