import { Dropdown } from "semantic-ui-react";
import React from "react";

function AddRegistrationButton({ options, ...rest }) {
  return (
    <Dropdown
      text="Add"
      icon="plus"
      floating
      labeled
      button
      pointing="top right"
      className="icon primary"
      {...rest}
    >
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option.key}
            value={option.key}
            onClick={option.onClick}
          >
            {option.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AddRegistrationButton;
