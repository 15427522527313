import React from "react";
import PropTypes from "prop-types";
import { GooglePlacesTypeahead } from "../../../../../../../shared/form";
import { Controller } from "react-hook-form";
import { FormField } from "../../../../../../../shared/semantic-form";
import { selectError } from "../../../../../../../shared/form/support";

function EventLocationAddress({ control, errors, disabled, ...rest }) {
  return (
    <Controller
      name="addressLine1"
      control={control}
      rules={{
        required: "Required",
      }}
      render={({ field, ...props }) => {
        return (
          <FormField
            label="Address"
            disabled={disabled}
            error={selectError(() => errors.addressLine1)}
          >
            <GooglePlacesTypeahead
              {...props}
              {...field}
              {...rest}
              disabled={disabled}
              autoComplete="off"
              placeholder="Enter Address"
            />
          </FormField>
        );
      }}
    />
  );
}

EventLocationAddress.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default EventLocationAddress;
