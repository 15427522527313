import {
  collection,
  firestore,
  firestoreCollectionQuery,
  query,
} from "@sempra-event-registration/common";

export const useEventRegistrations = firestoreCollectionQuery(
  (eventId) => query(collection(firestore, `events/${eventId}/registrants`)),
)
  .condition((eventId) => eventId != null)
  .map((snapshot) =>
    snapshot.docs.map((document) => ({
      ...document.data(),
      id: document.id,
    })),
  )
  .build();
