import React from "react";
import PropTypes from "prop-types";
import ReactNumberFormat from "react-number-format";
import { FormField } from "../../../semantic-form";

// TODO separate out PhoneInput, NumberInput, ZipCodeInput, this component is trying to do too much
function NumberInput({ label, disabled, error, onChange, type, ...rest }) {
  const format =
    type === "phone"
      ? "(###) ### - ####"
      : type === "zipCode"
      ? "#####"
      : undefined;
  return (
    <FormField {...{ label, disabled, error }}>
      <ReactNumberFormat
        thousandSeparator={type === "number"}
        onValueChange={(values) => {
          // Return value of correct data typing
          const value = ["phone", "zipCode"].includes(type)
            ? values.value
            : values.floatValue;
          onChange(value);
        }}
        {...{
          disabled,
          format,
          ...rest,
        }}
      />
    </FormField>
  );
}

NumberInput.defaultProps = { type: "number" };

NumberInput.propTypes = {
  allowNegative: PropTypes.bool,
  // decimalScale: sets how many decimals the input can allow.
  disabled: PropTypes.bool,
  decimalScale: PropTypes.number,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["number", "usd", "phone", "zipCode"]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
};
export default NumberInput;
