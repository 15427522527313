import React from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./EventRegistrationDeadline.module.css";
import ReactDatePicker from "react-datepicker";
import { FormField } from "../../../../../../../shared/semantic-form";
import { selectError } from "../../../../../../../shared/form/support";
import { endOfToday, subDays } from "date-fns";

function EventRegistrationDeadline({ control, disabled, errors }) {
  const { watch } = useFormContext();
  const date = watch("date");
  return (
    <Controller
      {...{ control }}
      name="registrationDeadline"
      rules={{
        required: "Required",
      }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <FormField
            label="Registration Deadline"
            disabled={disabled}
            error={selectError(() => errors.registrationDeadline)}
          >
            <ReactDatePicker
              wrapperClassName={styles.wrapper}
              dateFormat="MMMM d, yyyy"
              selected={value}
              minDate={endOfToday()}
              maxDate={date != null ? subDays(date, 1) : null}
              {...{ onChange, onBlur, disabled }}
            />
          </FormField>
        );
      }}
    />
  );
}

EventRegistrationDeadline.prototype = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default EventRegistrationDeadline;
