import React from "react";
import { Table } from "semantic-ui-react";

function TableRow({
  row,
  rowKey,
  rowOptions,
  rowProps,
  onRowClick,
  columns,
  defaultCell,
  userSelect = true,
  ...rest
}) {
  return (
    <Table.Row
      onClick={onRowClick != null ? (e) => onRowClick(e, row) : null}
      style={userSelect ? {} : { userSelect: "none" }}
      {...rowProps(row)}
      {...rest}
    >
      {columns.map((column) => {
        const columnKey = `${rowKey}-${column.id}`;
        const value = column.value ? column.value(row) : row;
        const format = column.format || ((v) => v);
        const content = value ? format(value, row) : defaultCell;
        const semanticTableCellProps = {
          textAlign: column.textAlign,
          ...(typeof column.cellProps === "function"
            ? column.cellProps(value, row)
            : {}),
        };

        return (
          <Table.Cell
            key={columnKey}
            className={`${column.id} ${column.className || ""} ${
              column.bodyClassName || ""
            }`}
            {...semanticTableCellProps}
          >
            {content}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
}

export default TableRow;
