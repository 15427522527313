import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Pagination,
  Table,
  Visibility,
} from "semantic-ui-react";
import TableHeaderRow from "./components/TableHeaderRow";
import TableRow from "./components/TableRow";
import { prepDataForCSV, rowComparator, searchFilter } from "./helpers";
import classes from "./SemanticDataTable.module.css";
import TableRowControls from "./components/TableRowControls";
import { csvGenerator } from "../../../../utils/csvGenerator";

/**
 * @param columns The column definitions
 * <p>
 * Each column has its own configuration to define the following:
 * <ul>
 *   <li>id: The column's unique identifier</li>
 *   <li>title: Optional text or component displayed in the column header</li>
 *   <li>value: Optional accessor used to get the field value to sort on or format</li>
 *   <li>dataType: Optional data type used to compute default formatting and comparators</li>
 *   <li>format: Optional function used to custom format the cell data</li>
 *   <li>sortable: Optionally set this to true to make the column sortable (default: false)</li>
 *   <li>comparator: Optional function used to compute the sort order (default: string comparison)</li>
 *   <li>searchableValue: Optional function used to compute the value used to filter with the search string</li>
 *   <li>csvValue: Optional function used to compute the CSV download specific cell value</li>
 *   <li>headClassName: The class name to apply to the column's th</li>
 *   <li>bodyClassName: The class name to apply to the column's td</li>
 *   <li>cellProps: Optional function that returns props to apply to the {@link Table.Cell}</li>
 * </ul>
 * </p>
 * <p>
 * To add a new column to the table, add a new configuration object o the {@link columns} array that
 * describes the new column.
 * </p>
 *
 * @param additionalCSVData We are passing in a collection of data and only displaying whats in the rows, but you might want to download more than what's there. These allow you to add extra data to the CSV, as long as it's also passed in the rows data
 * @param rows The table row data
 * @param onRowClick [optional] function called when a row is clicked
 * @param rowKeyProvider [optional] function that should return a unique key for the given row
 * @param rowOptions [optional] function that returns a list of menu options to display when clicking on the row menu
 * @param rowProps [optional] function that returns a set of properties to apply to the semantic {@link Table.Row}
 * @param defaultSort [optional] default sort configuration
 * @param defaultCell [optional] default text or component to show when there is no data for the cell
 * @param search [optional] a search string used to filter rows
 * @param searchEnabled When true, enables the search box at the top right of the table (default true)
 * @param searchPlaceholder The placeholder text to use in the table search box (default "Search...")
 * @param onSearchChange The function called when the search changes
 * @param paginationEnabled When true, the table rows will be separated into pages and page controls appear at the bottom (default false)
 * @param pageSize The number of rows to display per page
 * @param controls Top right display slot. This prop accepts a react component or string to render.
 * @param stickyControls When true, enables the top control bar of the table to scroll with you (default false)
 * @param stickyControlsOffset The sticky offset of the table's top control bar
 * @param multiSelect When true, each row will have a checkbox column to the left
 * @param selectedRows Array of selected rows
 * @param onSelectedRowsChange function called when the selected rows change
 * @param csvDownloadEnabled When true, a "Download as CSV" button will appear at the top right. When clicked, a CSV representation of the table will be downloaded.
 * @param semanticTableProps The properties to pass down to the semantic-ui Table component
 */
function SemanticDataTable({
  additionalCSVData = [],
  columns: providedColumns,
  rows: providedRows,
  onRowClick,
  rowKeyProvider,
  rowOptions,
  rowProps = () => ({}),
  defaultSort = [],
  defaultCell = "",
  initialSearch,
  search: providedSearch,
  searchEnabled = true,
  searchPlaceholder = "Search...",
  onSearchChange = () => {},
  paginationEnabled = false,
  pageSize = 10,
  className,
  controls,
  stickyControls = false,
  stickyControlsOffset = 0,
  multiSelect = false,
  selectedRows: providedSelectedRows,
  onSelectedRowsChange = () => {},
  csvDownloadEnabled = false,
  csvFileName = "sempra-event-registration-report.csv",
  ...semanticTableProps
}) {
  const [rows, setRows] = useState(providedRows);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(defaultSort);
  const [search, setSearch] = useState(initialSearch || "");
  const [selectedRows, setSelectedRows] = useState(providedSelectedRows);
  const [visibleRows, setVisibleRows] = useState(
    paginationEnabled ? rows.length : pageSize
  );

  const setRowsInternal = useCallback(
    (rows) => {
      paginationEnabled ? setPage(1) : setVisibleRows(pageSize);
      setRows(rows);
    },
    [pageSize, paginationEnabled]
  );

  const handleSearchChange = useCallback(
    (search) => {
      paginationEnabled ? setPage(1) : setVisibleRows(pageSize);
      setSearch(search);
      onSearchChange(search);
    },
    [paginationEnabled, onSearchChange, pageSize]
  );

  const setSelectedRowsInternal = useCallback(
    (rows) => {
      setSelectedRows(rows);
      onSelectedRowsChange(rows);
    },
    [onSelectedRowsChange]
  );

  function setVisibleRowsInternal(count) {
    setVisibleRows(Math.min(count, rows.length));
  }

  // On rows change reset pagination state
  useEffect(() => {
    if (rows !== providedRows) {
      setRowsInternal(providedRows);
    }
  }, [rows, providedRows, setRowsInternal]);

  // On change to provided selected rows update the internal state to match
  useEffect(() => {
    if (selectedRows !== providedSelectedRows) {
      setSelectedRowsInternal(providedSelectedRows);
    }
  }, [providedSelectedRows, selectedRows, setSelectedRowsInternal]);

  const columns = [...providedColumns];

  if (multiSelect) {
    onRowClick = (e, row) => {
      const selected = !selectedRows.includes(row);

      const nextSelectedRows = selected
        ? [...selectedRows, row]
        : selectedRows.filter((x) => x !== row);

      setSelectedRowsInternal(nextSelectedRows);
    };

    columns.unshift({
      id: "_checkbox",
      cellProps: () => ({ collapsing: true }),
      format: (row) => (
        <Checkbox
          style={{ pointerEvents: "none" }}
          checked={selectedRows.some((x) => x === row)}
        />
      ),
    });
  } else if (typeof rowOptions === "function") {
    columns.push({
      id: "_options",
      cellProps: () => ({ collapsing: true }),
      bodyClassName: classes.optionCell,
      format: (row) => (
        <TableRowControls
          className={classes.rowControls}
          options={rowOptions(row)}
        />
      ),
    });
  }

  const sortComparator = rowComparator(sort, columns);

  // Logic for handling incoming rows
  const searchedRows = search
    ? rows.filter(searchFilter(columns, search))
    : rows;

  const sortedRows =
    sort.length > 0 ? searchedRows.sort(sortComparator) : searchedRows;

  // Apply pagination
  const pagedRows = paginationEnabled
    ? sortedRows.slice((page - 1) * pageSize, page * pageSize)
    : sortedRows.slice(0, visibleRows);

  // Table Config
  const sortable = columns.some((column) => column.sortable);

  // Pagination Config
  const totalPages = Math.ceil(sortedRows.length / pageSize);

  function onBottomVisible() {
    // Show more rows when the bottom is visible
    if (visibleRows < rows.length) {
      setVisibleRowsInternal(visibleRows + pageSize);
    }
  }

  function onDownloadCSVClick() {
    const filtered = columns.filter((e) => e.id !== "_options");
    const csvColumns = [...filtered, ...additionalCSVData];
    csvGenerator(prepDataForCSV(csvColumns, sortedRows), csvFileName);
  }

  return (
    <div>
      {(controls || searchEnabled || csvDownloadEnabled) && (
        <div
          className={`${classes.topControls} ${
            stickyControls ? classes.sticky : ""
          }`}
          style={stickyControls ? { top: stickyControlsOffset } : {}}
        >
          <div>
            {multiSelect && (
              <Checkbox
                label="Select all"
                className={classes.selectAllCheckbox}
                checked={rows.length === selectedRows.length}
                onChange={(e, { checked }) => {
                  setSelectedRowsInternal(checked ? rows : []);
                }}
              />
            )}
          </div>
          <div className={classes.controls}>
            {searchEnabled && (
              <Input
                icon="search"
                placeholder={searchPlaceholder}
                className={classes.searchInput}
                value={search}
                onInput={(e) => handleSearchChange(e.target.value)}
              />
            )}
            {csvDownloadEnabled && (
              <div>
                <Button
                  onClick={onDownloadCSVClick}
                  type="button"
                  inverted
                  color="blue"
                >
                  <Icon name="download" />
                  Download as CSV
                </Button>
              </div>
            )}
            {controls && controls}
          </div>
        </div>
      )}
      <Table
        {...{
          sortable,
          selectable: typeof onRowClick === "function" && !multiSelect,
          className: `${classes.table} ${className || ""}`,
          ...semanticTableProps,
        }}
      >
        <TableHeaderRow {...{ columns, sort, setSort, defaultSort }} />
        <Visibility
          as={Table.Body}
          once={false}
          onBottomVisible={onBottomVisible}
        >
          {pagedRows.map((row, rowIndex) => {
            const key = !!rowKeyProvider ? rowKeyProvider(row) : rowIndex;
            return (
              <TableRow
                {...{
                  key,
                  rowKey: key,
                  row,
                  onRowClick,
                  columns,
                  defaultCell,
                  rowOptions,
                  rowProps,
                  userSelect: !multiSelect,
                  active: multiSelect && selectedRows.includes(row),
                }}
              />
            );
          })}
        </Visibility>
      </Table>
      {paginationEnabled && totalPages > 1 && (
        <div className={classes.bottomControls}>
          <Pagination
            activePage={page}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            totalPages={totalPages}
            onPageChange={(event, { activePage }) => setPage(activePage)}
          />
        </div>
      )}
    </div>
  );
}

export default SemanticDataTable;
