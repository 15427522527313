import {
  copyToClipboard,
  eventToEventTimeStatus,
} from "@sempra-event-registration/common";
import { eventUrl } from "./eventUrls";
import { Link } from "react-router-dom";
import { viewDataOption } from "../../shared/development";

function editOption({ event, isAuthorOrSuperUser }) {
  return {
    key: "edit",
    text:
      isAuthorOrSuperUser &&
      ["draft", "open"].includes(eventToEventTimeStatus(event))
        ? "Edit"
        : "View Details",
    as: Link,
    to: `/events/${event.id}`,
    enabled: ({ viewing }) => !viewing,
  };
}

function viewOption({
  event,
  internalRegistrationBaseUrl,
  externalRegistrationBaseUrl,
}) {
  const url = eventUrl(event, {
    internalRegistrationBaseUrl,
    externalRegistrationBaseUrl,
  });

  return {
    key: "view",
    text: event.status === "draft" ? "Preview" : "View",
    as: "a",
    href: url,
    target: "_blank",
    enabled: ({ event }) => event.id !== undefined,
  };
}

function viewRegistration({ event, isAuthorOrSuperUser }) {
  return {
    key: "registration",
    text: "Registration",
    as: Link,
    to: `/events/${event.id}/registration`,
    enabled: ({ event }) => event.status !== "draft" && isAuthorOrSuperUser,
  };
}

function deleteOption({ event, onClick, isAuthorOrSuperUser }) {
  return {
    key: "delete",
    text: "Delete",
    onClick: () => onClick(event),
    enabled: ({ event }) => event.status === "draft" && isAuthorOrSuperUser,
  };
}

function cancelOption({ event, onClick, isAuthorOrSuperUser }) {
  return {
    key: "cancel",
    text: "Cancel",
    onClick: () => onClick(event),
    enabled: ({ event, user }) =>
      event.status === "published" && isAuthorOrSuperUser,
  };
}

function copyRegistrationUrlOption({
  event,
  internalRegistrationBaseUrl,
  externalRegistrationBaseUrl,
}) {
  return {
    key: "copy-url",
    text: "Copy URL",
    onClick: async () => {
      return copyToClipboard(
        eventUrl(event, {
          internalRegistrationBaseUrl,
          externalRegistrationBaseUrl,
        })
      );
    },
    enabled: ({ event }) => event.status === "published",
  };
}

function cloneOption({ event }) {
  return {
    key: "clone",
    text: "Clone",
    as: Link,
    to: {
      pathname: `/events/${event.type}`,
      search: `?source=${event.id}`,
    },
  };
}

function reportsOption({ event, isAuthorOrSuperUser }) {
  return {
    key: "reports",
    text: "Reports",
    as: Link,
    to: `/events/${event.id}/reports`,
    enabled: ({ event }) => event.status === "published" && isAuthorOrSuperUser,
  };
}

export function eventMenuOptionFactory({
  user,
  onDeleteOptionClick,
  onCancelOptionClick,
  externalRegistrationBaseUrl,
  internalRegistrationBaseUrl,
  viewing,
}) {
  return (event) => {
    // event model is not consistent between events page and event page
    const isAuthorOrSuperUser =
      event.createdBy === user.id || user.roles.includes("super_user");
    const context = {
      event,
      isAuthorOrSuperUser,
      user,
      externalRegistrationBaseUrl,
      internalRegistrationBaseUrl,
      viewing,
    };
    return [
      viewDataOption(`events/${event.id}`),
      editOption(context),
      viewOption(context),
      viewRegistration(context),
      reportsOption(context),
      copyRegistrationUrlOption(context),
      cloneOption(context),
      deleteOption({ ...context, onClick: onDeleteOptionClick }),
      cancelOption({ ...context, onClick: onCancelOptionClick }),
    ]
      .filter((option) => option.enabled == null || option.enabled(context))
      .map((option) => {
        const { enabled, ...pruned } = option;
        return pruned;
      });
  };
}
