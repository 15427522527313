import React from "react";
import { eventTimeStatusNameByValue } from "@sempra-event-registration/common";
import { Label } from "semantic-ui-react";
import classes from "./EventTimeStatusBadge.module.css";

function EventTimeStatusBadge({ status }) {
  return (
    <Label className={classes[status]}>
      {eventTimeStatusNameByValue[status]}
    </Label>
  );
}

export default EventTimeStatusBadge;
