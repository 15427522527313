import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Controller } from "react-hook-form";
import { FormField } from "../../../../../../../shared/semantic-form";

function EventMessageSelector({
  control,
  onChangeMessageSelection,
  options,
  ...rest
}) {
  return (
    <Controller
      name="messageName"
      control={control}
      defaultValue=""
      render={() => (
        <FormField>
          <Dropdown
            placeholder="Select Email Message"
            options={options}
            fluid
            selection
            onChange={(_, { value }) => {
              onChangeMessageSelection(value);
            }}
          />
        </FormField>
      )}
    />
  );
}

export default EventMessageSelector;
