import React from "react";
import groupRegistrantsByUnionRepresentation from "../page/EventReportsPage/reports/groupRegistrantsByUnionRepresentation";
import groupRegistrantsByGroupAndSubgroup from "../page/EventReportsPage/reports/groupRegistrantsByGroupAndSubgroup";
import CapacityFraction from "../../EventsPage/component/CapacityFraction/CapacityFraction";
import classes from "../../EventsPage/component/EventTable/EventTable.module.css";
import { Label } from "semantic-ui-react";
import { groupRegistrantsByWorkshop } from "../page/EventReportsPage/reports/groupRegistrantsByWorkshop";
import { format } from "date-fns";
import groupRegistrantsByMeal from "../page/EventReportsPage/reports/groupRegistrantsByMeal";

const registrantsByGroupOption = ({ event, registrations }) => ({
  title: "Registrants by Group",
  key: "registrants-by-group",
  columns: [
    {
      id: "groupId",
      title: "Group Name",
      sortable: true,
      dataType: "string",
      value: (x) => x.groupName,
      csvValue: (x) => x.groupName,
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "subGroupId",
      title: "Subgroup Name",
      sortable: true,
      dataType: "string",
      value: (x) => x.subGroupName,
      csvValue: (x) => x.subGroupName,
    },
    {
      id: "subGroupCapacity",
      title: "Number of Registrants / Group Size",
      sortable: true,
      dataType: "number",
      sortableValue: (x) => x.count || 0,
      csvValue: (x) =>
        x.subGroupCapacity === 0
          ? `${x.count}`
          : `"${x.count} / ${x.subGroupCapacity}"`,
      format: (x) => {
        return x.subGroupCapacity === 0 ? (
          <Label>{x.count}</Label>
        ) : (
          <Label
            className={
              x.count === x.subGroupCapacity
                ? classes.atCapacity
                : x.count > x.subGroupCapacity
                ? classes.overCapacity
                : ""
            }
          >
            <CapacityFraction top={x.count} bottom={x.subGroupCapacity} />
          </Label>
        );
      },
      textAlign: "right",
      cellProps: () => ({ collapsing: true }),
    },
  ],
  rows: () => groupRegistrantsByGroupAndSubgroup(registrations, event),
});

const registrantsByMealOption = ({ event, registrations }) => ({
  title: "Registrants by Meal",
  key: "registrants-by-meal",
  defaultSort: [[]],
  columns: [
    {
      id: "mealName",
      title: "Meal",
      dataType: "string",
      sortable: true,
      value: (x) => x.mealName,
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "optionName",
      title: "Meal Option",
      dataType: "string",
      sortable: true,
      value: (x) => x.optionName,
    },
    {
      id: "count",
      title: "Number of Registrants",
      dataType: "number",
      sortable: true,
      value: (x) => x.count,
      textAlign: "right",
      cellProps: () => ({ collapsing: true }),
    },
  ],
  rows: () => groupRegistrantsByMeal(registrations, event),
});

const registrantsByUnionRepOption = ({ registrations }) => ({
  title: "Registrants by Union Representation",
  key: "registrants-by-union-rep",
  defaultSort: [["response", "ascending"]],
  columns: [
    {
      id: "response",
      title: "Represented by a Union",
      sortable: true,
      dataType: "string",
      value: (x) => x.response,
      format: (response) => `${response[0].toUpperCase()}${response.slice(1)}`,
      csvValue: ({ response }) => {
        return `${response[0].toUpperCase()}${response.slice(1)}`;
      },
    },
    {
      id: "count",
      title: "Number of Registrants",
      sortable: true,
      dataType: "number",
      value: (x) => x.count,
      textAlign: "right",
      cellProps: () => ({ collapsing: true }),
    },
  ],
  rows: () => groupRegistrantsByUnionRepresentation(registrations),
});

const registrantsByWorkshopOption = ({ event, registrations }) => ({
  title: "Registrants by Workshop",
  key: "registrants-by-workshop",
  defaultSort: [
    ["name", "ascending"],
    ["startTime", "ascending"],
  ],
  columns: [
    {
      id: "name",
      title: "Name",
      sortable: true,
      value: (x) => x.name,
    },
    {
      id: "startTime",
      title: "Start Time",
      sortable: true,
      dataType: "date",
      value: (x) => x.startTime,
      format: (startTime) => format(new Date(startTime.toString()), "h:mm aa"),
      csvValue: ({ startTime }) =>
        format(new Date(startTime.toString()), "h:mm aa"),
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "facilitator",
      title: "Facilitator",
      sortable: true,
      value: (x) => x.facilitator,
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "count",
      title: "Number of Registrants",
      sortable: true,
      value: (x) => x.count,
      dataType: "number",
      textAlign: "right",
      cellProps: () => ({ collapsing: true }),
    },
  ],
  rows: () => groupRegistrantsByWorkshop(registrations, event),
});

export function getReportOptions(event, registrations) {
  const context = { event, registrations };
  return [
    registrantsByGroupOption(context),
    registrantsByMealOption(context),
    registrantsByWorkshopOption(context),
    registrantsByUnionRepOption(context),
  ];
  // TODO filter out reports that don't make sense
}
