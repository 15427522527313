import { GOOGLE_CALLBACK_NAME } from "../constants/google";
import { app } from "@sempra-event-registration/common";

export default function addGoogleMapsAPIScript() {
  const src = `https://maps.googleapis.com/maps/api/js?key=${app.options.apiKey}&libraries=places&callback=${GOOGLE_CALLBACK_NAME}`;

  if (!window.google) {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    script.onload = () =>
      console.log("Google Maps script loaded successfully.");
    script.onerror = () =>
      console.error("Failed to load the Google Maps script.");
  } else {
    console.log("Google Maps is already loaded.");
  }
}
