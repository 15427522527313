import React from "react";
import { Segment } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import classes from "./EventFormSection.module.css";

// speciallizedProps are for handing props for a specific section of the form through to that section.
// You can see this with 'Event Details Section' with description being passed through.
function EventFormSection({
  sectionRef,
  section,
  readonly,
  event,
  editing,
  ...specializedProps
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const sectionProps = {
    event,
    editing,
    fieldProps: {
      control,
      errors,
      disabled: readonly,
    },
    ...specializedProps,
  };
  return (
    <Segment className={classes.container}>
      <div id={section.id} className={classes.titleContainer} ref={sectionRef}>
        {section.hideTitle || <h2>{section.title}</h2>}
      </div>
      {section.component(sectionProps)}
    </Segment>
  );
}

export default EventFormSection;
