const nameSuffix = "(Copy)";

function resetSubgroupRegistrationCount(groups = []) {
  groups.forEach((group) => {
    let { subgroups = [] } = group;
    subgroups.forEach((subgroup) => {
      if (subgroup.registrationCount) subgroup.registrationCount = 0;
    });
  });
  return groups;
}

export function eventCopy(event) {
  return {
    ...event,
    status: "draft",
    name: event.name.endsWith(nameSuffix)
      ? event.name
      : `${event.name} ${nameSuffix}`,
    groups: resetSubgroupRegistrationCount(event.groups),
    reservationCount: 0
  };
}
