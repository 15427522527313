import React from "react";
import { Form } from "semantic-ui-react";
import { Controller } from "react-hook-form";

function Checkbox({ control, name, onChange = () => {}, ...rest }) {
  return (
    <Controller
      {...{ control, name }}
      render={({ field: { value, onChange: onChangeInternal, onBlur } }) => (
        <Form.Checkbox
          data-testid="virtualEventCheckbox"
          checked={value || false}
          onChange={(e, { checked }) => {
            onChangeInternal(checked);
            onChange(checked);
          }}
          {...{ onBlur, ...rest }}
        />
      )}
    />
  );
}

export default Checkbox;
