import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Form, Message } from "semantic-ui-react";

function Dropdown({
  control,
  label,
  name,
  options,
  error = {},
  rules,
  placeholder,
  onChange = () => {},
  onBlur = () => {},
  defaultValue,
  ...rest
}) {
  return (
    <Controller
      {...{ control, name, rules, defaultValue }}
      render={({
        field: { onBlur: onBlurInternal, onChange: onChangeInternal },
        value,
      }) => (
        <>
          <Form.Select
            {...{
              label,
              onBlur,
              options,
              placeholder,
              value,
              error: error.message,
              ...rest,
            }}
            onChange={(e, { value }) => {
              onChangeInternal(value);
              onChange(value);
            }}
            onBlur={(e) => {
              onBlurInternal(e);
              onBlur(e);
            }}
          />
          {!!error.message && (
            <Message error={!!error.message} header="Required" />
          )}
        </>
      )}
    />
  );
}

Dropdown.prototype = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

export default Dropdown;
