import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";

// This allows for the form submit button to be detached from the form
// The composition is this: EventPage > EventForm > ...page components including form fields
function EventForm({ defaultValues, readonly, children }) {
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  // Form validation is manually invoked therefore handleSubmit is not used
  return (
    <Form className={readonly ? "readonly" : ""}>
      <FormProvider {...form}>{children}</FormProvider>
    </Form>
  );
}

export default EventForm;
