import React from "react";
import { Controller } from "react-hook-form";
import { FormField } from "../../../semantic-form";
import ReactDatePicker from "react-datepicker";

function TimeInput({
  control,
  name,
  defaultValue,
  disabled,
  rules,
  error,
  label,
  minTime,
  maxTime,
  ...rest
}) {
  return (
    <Controller
      {...{ control, name, defaultValue, rules }}
      render={({ field: { onChange, onBlur, value } }) => {
        /* 
          NOTE: ReactDatePicker errors if a minTime and endTime 
          is not provided 
        */
        return (
          <FormField {...{ label, disabled, error }}>
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm aa"
              selected={value}
              minTime={minTime}
              maxTime={maxTime}
              {...{ onChange, onBlur, disabled, ...rest }}
            />
          </FormField>
        );
      }}
    />
  );
}

export default TimeInput;
