import React, { useState } from "react";
import { Button, Checkbox, Icon, Label, Popup } from "semantic-ui-react";
import classes from "./EventFilters.module.css";

const defaultValues = {
  search: "",
  onlyMyEvents: false,
  onlyPastEvents: false,
};

// Counts filters that are not set to their default value
// This excludes search string as it is controlled outside of the number-badged filters popover
function valuesToFilterCount(values) {
  let count = 0;
  if (values.onlyMyEvents) {
    count++;
  }
  if (values.onlyPastEvents) {
    count++;
  }
  return count;
}

function EventFilters({ value, onChange = () => {}, ...rest }) {
  const [showFilters, setShowFilters] = useState(false);

  const values = {
    ...defaultValues,
    ...(value || {}),
  };

  const filterCount = valuesToFilterCount(values);

  return (
    <div className={classes.controls} {...rest}>
      <Popup
        on="click"
        trigger={
          <Button type="button" inverted color="blue">
            <Icon name="filter" />
            Filters
            {filterCount > 0 && (
              <Label
                className={classes.floatingLabel}
                color="blue"
                circular
                floating
              >
                {filterCount}
              </Label>
            )}
          </Button>
        }
        eventsEnabled
        open={showFilters}
        onOpen={() => setShowFilters(true)}
        onClose={() => setShowFilters(false)}
        position="bottom right"
        flowing
        hoverable={false}
        hideOnScroll={true}
      >
        <div className={classes.filterPopupContainer}>
          <div className={classes.filterColumn}>
            <Checkbox
              toggle
              label="My events"
              checked={values.onlyMyEvents}
              onChange={(e, { checked }) => {
                onChange({
                  ...values,
                  onlyMyEvents: checked,
                });
              }}
            />
            <Checkbox
              toggle
              label="Past events"
              checked={values.onlyPastEvents}
              onChange={(e, { checked }) => {
                onChange({
                  ...values,
                  onlyPastEvents: checked,
                });
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default EventFilters;
