import React from "react";
import { useFormContext } from "react-hook-form";
import { RichTextEditor } from "../../../../../../../shared/form";
import { FormField } from "../../../../../../../shared/semantic-form";

function EventDescription({
  disabled,
  additionalDirections,
  setAdditionalDirections,
}) {
  const { register, setValue } = useFormContext();

  return (
    <FormField label="Additional Directions" disabled={disabled}>
      {/* This is here to allow forms to know if it's been edited - work around*/}
      <input type="hidden" {...register("additionalDirectionsEdited")} />
      <RichTextEditor
        {...{ disabled }}
        value={additionalDirections}
        placeholder="Add additional direction to the location, park, door access, ect."
        onChange={(value) => {
          // setting value to tell the form that it's dirty. Hack for getting the ability to save when you've only edited additionalDirections.
          setValue("additionalDirectionsEdited", new Date().toISOString(), {
            shouldDirty: true,
          });
          setAdditionalDirections(value);
        }}
      />
    </FormField>
  );
}

export default EventDescription;
