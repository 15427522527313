import { useEffect } from "react";
import { remoteConfigReference } from "./remoteConfigReference";
import { useRemoteConfigContext } from "./RemoteConfigContext";
import { fetchAndActivate } from "firebase/remote-config";
import { errorResource, loadedResource } from "../../resource";

export function useRemoteConfig() {
  const [remoteConfig, setRemoteConfig] = useRemoteConfigContext();
  useEffect(() => {
    fetchAndActivate(remoteConfigReference)
      .then((value) => {
        setRemoteConfig(loadedResource(remoteConfigReference));
      })
      .catch((error) => {
        setRemoteConfig(errorResource(error));
      });
  }, []);
  return remoteConfig;
}
