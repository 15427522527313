/**
 * Create an object that associates a meal to its option(s).
 * <p>
 *   <strong>NOTE:</strong> it is possible to have a meal with no option.  In that case, the optionId and optionName
 *   will be null.
 * </p>
 *
 * @param meal The meal from an event
 * @param option One of the meal's options (can be null)
 * @returns {{mealId: string, count: number, optionId: (string|null), mealName: string, optionName: (string|null)}}
 */
function mapMealWithOption(meal, option = null) {
  return {
    mealId: meal.id,
    mealName: meal.name,
    optionId: option?.id || null,
    optionName: option?.name || null,
    count: 0,
  };
}

/**
 * Get the combination of meals and their associated options into a "flattened" collection of rows.
 *
 * @example
 * // example output
 * [
 *   {mealId: "1", mealName: "first meal", optionId: "10", optionName: "first option", count: 0},
 *   {mealId: "1", mealName: "first meal", optionId: "11", optionName: "second option", count: 0},
 *   {mealId: "2", mealName: "second meal", optionId: "20", optionName: "x option", count: 0},
 *   {mealId: "2", mealName: "second meal", optionId: "21", optionName: "y option", count: 0},
 *   {mealId: "2", mealName: "second meal", optionId: "22", optionName: "z option", count: 0},
 *   {mealId: "3", mealName: "second meal", optionId: null, optionName: null, count: 0},
 * ]
 *
 * @param event {Object} The event containing the meals and their associated options
 * @returns {Array} An array of objects representing the meal -> option combinations configured in the event
 */
function getEventMealsAndOptionsMatrix(event) {
  return event.meals.reduce((accumulator, meal) => {
    const flattenedMealWithOptions =
      meal.options.length > 0
        ? meal.options.map((option) => mapMealWithOption(meal, option))
        : mapMealWithOption(meal);

    return accumulator.concat(flattenedMealWithOptions);
  }, []);
}

/**
 * Count the number of registrants that have selected each meal -> option combination.
 * <p>
 *   This report returns rows for <em>all</em> meal -> option combinations.  Even if no registrants chose a particular
 *   meal -> option combination, that combination will still be returned with a count of zero.  This allows the event
 *   admins to identify which (if any) meal -> option combinations were not selected by anyone.
 * </p>
 *
 * @param registrants {Array} A collection of users that are for an event
 * @param event {Object} The event whose meal -> option combinations are being counted
 * @returns {Array} A collection of all meal -> option combinations for the event and how many registrants have selected
 * each combination
 */
export default function groupRegistrantsByMeal(registrants, event) {
  const eventMealsWithOptions = getEventMealsAndOptionsMatrix(event);

  registrants
    .flatMap((registrant) => registrant.meals)
    .forEach((meal) => {
      const existingMealWithOption = eventMealsWithOptions.find(
        (existing) =>
          existing.mealId === meal.id &&
          existing.optionId === (meal.option?.id || null)
      );

      if (existingMealWithOption) {
        existingMealWithOption.count++;
      }
    });

  return eventMealsWithOptions;
}
