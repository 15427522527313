import { differenceInMinutes } from "date-fns";
import { timeOf } from "../date";

/**
 * Verify the number of minutes between two times is greater than or equal to the specified minimum.
 *
 * @param startTime {Date} The earlier of the two dates to compare
 * @param endTime {Date} The later of the two dates to compare
 * @param minimumTimespan {number} The number of minutes that end time minutes - start time minutes should be greater than
 * @param message {string} The error message that should be returned when validation fails
 * @returns {string|null} An error message if the number of minutes between start time and end time is less than the minimum value, otherwise null
 */
export function verifyMinimumTimespan(
  startTime,
  endTime,
  minimumTimespan,
  message
) {
  const durationInMinutes = differenceInMinutes(
    timeOf(endTime),
    timeOf(startTime)
  );

  return durationInMinutes < minimumTimespan ? message : null;
}
