export function requiredRichText(message = "Required") {
  return (editorState) => {
    if (!editorState.getCurrentContent().hasText()) {
      return message;
    }

    // Purpose of this check is to throw an error if only spaces are added into the description
    // Spaces should not satisfy the 'required' constraint
    if (
      editorState.getCurrentContent().getPlainText("\u0001").trim().length === 0
    ) {
      return message;
    }

    return null;
  };
}
