import React from "react";
import { format } from "date-fns-tz";
import { SemanticDataTable } from "../../../../../../../shared/ui";
import { registrationStatusNameById } from "@sempra-event-registration/common/src/event";
import classes from "./RegistrationTable.module.css";
import {
  ordering,
  registrationStatuses,
  nullsFirst,
  dateComparator,
} from "@sempra-event-registration/common";
import RegistrationStatusBadge from "../RegistrationStatusBadge/RegistrationStatusBadge";

const collapsing = { collapsing: true };
const limitedWidth = classes.limitedWidth;

function RegistrationTable({
  registrations,
  rowOptions,
  onRowClick = () => {},
  eventType,
  askedIfRepresentedByUnion,
  askedForReleaseWaiver,
  ...rest
}) {
  const columns = [
    {
      id: "email",
      title: "Email",
      sortable: true,
      cellProps: () => collapsing,
      value: (x) => x.email?.toLowerCase() || "",
    },
    {
      id: "name",
      title: "Name",
      sortable: true,
      bodyClassName: limitedWidth,
      csvValue: (x) => `${x.firstName || ""} ${x.lastName || ""}`,
      value: (x) => `${x.firstName || ""} ${x.lastName || ""}`,
    },
    {
      id: "companyName",
      title: "Company",
      sortable: true,
      bodyClassName: limitedWidth,
      cellProps: () => collapsing,
      value: (x) => x.companyName || "",
    },
    ...(eventType !== "sdge_external"
      ? [
          {
            id: "department",
            title: "Department",
            sortable: true,
            bodyClassName: limitedWidth,
            cellProps: () => collapsing,
            csvValue: (x) => x.internalResource?.department || "",
            value: (x) => x.internalResource?.department || "",
          },
        ]
      : []),
    {
      id: "title",
      title: "Job Title",
      sortable: true,
      bodyClassName: limitedWidth,
      cellProps: () => collapsing,
      value: (x) => x.title || "",
    },
    {
      id: "status",
      title: "Status",
      sortable: true,
      comparator: ordering(registrationStatuses),
      cellProps: () => collapsing,
      value: (x) => x.status,
      searchableValue: (x) => registrationStatusNameById[x.status],
      format: (status) => <RegistrationStatusBadge status={status} />,
    },
    {
      id: "registrationStatusDate",
      title: "Status Date",
      sortable: true,
      cellProps: () => collapsing,
      value: (x) => x.updatedAt.toDate(),
      comparator: nullsFirst(dateComparator),
      format: (x) =>
        format(x, "MMM dd, yyyy", {
          timeZone: "America/Los_Angeles",
        }),
      csvValue: (x) =>
        format(x.updatedAt.toDate(), "MMM dd, yyyy", {
          timeZone: "America/Los_Angeles",
        }),
    },
  ];

  const additionalCSVData = [
    {
      title: "Phone",
      csvValue: (x) => x.phone || "",
    },
    {
      title: "First Name",
      csvValue: (x) => x.firstName || "",
    },
    {
      title: "Last Name",
      csvValue: (x) => x.lastName || "",
    },
    {
      title: "Company",
      csvValue: (x) => x.companyName || "",
    },
    ...(eventType !== "sdge_external"
      ? [
          {
            title: "Badge",
            csvValue: (x) => x.internalResource?.badgeNumber || "",
          },
          {
            title: "Supervisor First Name",
            csvValue: (x) => x.internalResource?.supervisor?.firstName || "",
          },
          {
            title: "Supervisor Last Name",
            csvValue: (x) => x.internalResource?.supervisor?.lastName || "",
          },
          {
            title: "Supervisor Phone",
            csvValue: (x) => x.internalResource?.supervisor?.phone || "",
          },
          {
            title: "Supervisor Email",
            csvValue: (x) => x.internalResource?.supervisor?.email || "",
          },
        ]
      : []),
    ...(askedIfRepresentedByUnion && eventType === "internal"
      ? [
          {
            title: "Represented By A Union?",
            csvValue: (x) => (x.representedByUnion ? "Yes" : "No"),
          },
        ]
      : []),
    ...(askedForReleaseWaiver && eventType === "internal"
      ? [
          {
            title: "Release Waiver?",
            csvValue: (x) => (x.acceptReleaseWaiver ? "Yes" : "No"),
          },
        ]
      : []),
  ];

  return (
    <SemanticDataTable
      csvDownloadEnabled
      singleLine
      striped
      className={classes.table}
      columns={columns}
      rows={registrations}
      rowOptions={rowOptions}
      rowKeyProvider={(x) => x.email}
      onRowClick={onRowClick}
      defaultSort={[["email"]]}
      {...{ ...rest, additionalCSVData }}
    />
  );
}

export default RegistrationTable;
