import React, { useState } from "react";
import { isEqual } from "date-fns";
import { Dropdown, Button } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import {
  eventStatusNameByValue,
  notificationWorthyEventChanges,
} from "@sempra-event-registration/common/src/event";
import {
  deleteDoc,
  useLoadedUser,
  doc,
  firestore,
} from "@sempra-event-registration/common";
import { IconButton } from "../../../../../../../shared/ui";
import PublishEventModal from "../../modal/PublishEventModal/PublishEventModal";
import SaveEventModal from "../../modal/SaveEventModal/SaveEventModal";
import DeleteEventModal from "../../../../../../component/DeleteEventModal/DeleteEventModal";
import CancelEventModal from "../../../../../../component/CancelEventModal/CancelEventModal";
import { eventMenuOptionFactory } from "../../../../../../support/eventMenuOptionFactory";
import { useAppConfig } from "../../../../../../../shared/config";
import SaveButton from "./component/SaveButton/SaveButton";
import { useEventRegistrants } from "../../../../../../hook/useEventRegistrants";
import { formValuesToEvent } from "../../../../support/eventForms";
import { scrollToFirstError } from "../../../../../../../shared/form";
import { useSearchRestoringHistory } from "../../../../../../../shared/router";
import { requiredRichText } from "../../../../../../support/requiredRichText";
import toastNotification from "../../../../../../../shared/toastNotification/toastNotification";
import classes from "./EventFormControls.module.css";

function EventFormControls({
  additionalDirections,
  description,
  event,
  readonly,
  editing,
  saving,
  defaultValues,
  savingStatus,
  onSave = () => {},
  setTriedToPublish,
  onPublish,
  onCancel,
}) {
  const { getValues, clearErrors, trigger, formState, reset, watch } =
    useFormContext();
  const user = useLoadedUser();
  const history = useSearchRestoringHistory();
  const { value: appConfig } = useAppConfig();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { status } = event;
  const registrantsResource = useEventRegistrants(user, event.id);

  const formUpdatedAt = watch("updatedAt");
  // Don't render until registrants are loaded
  const { status: resourceLoadStatus } = registrantsResource;
  if (resourceLoadStatus === "empty" || resourceLoadStatus === "loading") {
    return <></>;
  }

  const registrants = registrantsResource.value;
  const buttonsDisabled = saving || savingStatus;
  const showStatus = editing;
  const showSaveControl = !readonly && status !== "cancelled";
  const showPublishControl = !readonly && editing && status === "draft";
  const isDescriptionValid = !requiredRichText()(description);

  const eventMenuOptions = eventMenuOptionFactory({
    viewing: true,
    user,
    onDeleteOptionClick: () => setDeleteModalOpen(true),
    onCancelOptionClick: () => setCancelModalOpen(true),
    ...appConfig,
  })(event);

  const showOptionMenu = event.id != null && eventMenuOptions.length > 0;

  async function onSaveButtonClick() {
    clearErrors();

    // Check to see if updatedAt is diff from event
    // if true notify and do not save
    // if false save.
    if (
      defaultValues.updatedAt &&
      !isEqual(formUpdatedAt, defaultValues.updatedAt)
    ) {
      toastNotification({
        title: "Cannot save event",
        message:
          "The event has been changed by someone else, please refresh your page to get the latest info",
        type: "danger",
      });

      return;
    }

    // If a draft, only require the name field
    // If published, make sure the whole form is valid before saving
    // Description is not handled by react hook forms
    const valid = await (status === "draft"
      ? trigger("name")
      : trigger() && isDescriptionValid);

    if (valid) {
      const registrantCount = registrants.filter(
        ({ status }) => status === "registered",
      ).length;

      // 'description', and 'additionalDirections' is not handled by the form, so we need to pass it in for change checkes independently.
      const notificationWorthyChanges = notificationWorthyEventChanges(
        formValuesToEvent(defaultValues),
        formValuesToEvent({
          ...getValues(),
          description,
          additionalDirections,
        }),
      );

      if (
        status === "published" &&
        registrantCount > 0 &&
        notificationWorthyChanges.length > 0
      ) {
        setSaveModalOpen(true);
      } else {
        onSave(getValues(), reset);
      }
    } else {
      scrollToFirstError();
    }
  }

  function onSaveModalAccept({ sendChangeNotifications }) {
    setSaveModalOpen(false);
    onSave(
      {
        sendChangeNotifications,
        ...getValues(),
      },
      reset,
    );
  }

  async function onPublishButtonClick() {
    setTriedToPublish(true);
    clearErrors();
    const valid = (await trigger()) && isDescriptionValid;
    if (valid) {
      setPublishModalOpen(true);
    } else {
      scrollToFirstError();
    }
  }

  function onPublishModalAccept() {
    setPublishModalOpen(false);
    onPublish(getValues(), reset);
  }

  function onCancelModalAccept() {
    setCancelModalOpen(false);
    // Ignore in-flight changes when cancelling to ensure the form is valid without forcing validation
    onCancel(defaultValues);
  }

  async function onDeleteModalAccept() {
    setDeleteModalOpen(false);
    deleteDoc(doc(firestore, "events", event.id))
      .then((e) =>
        toastNotification({
          title: "SUCCESFULLY DELETED",
          message: `Event: ${event.name}`,
        }),
      )
      .catch((error) =>
        toastNotification({
          title: "ERROR WHILE DELETING",
          message: `Error: ${error}`,
          type: "danger",
        }),
      );

    history.push("/", { replace: true });
  }

  function statusText() {
    return (
      <span className={classes.status}>
        Status: <strong>{eventStatusNameByValue[status]}</strong>
      </span>
    );
  }

  function saveControl() {
    return (
      <SaveButton
        className={classes.actionButton}
        disabled={buttonsDisabled || (editing && !formState.isDirty)}
        loading={saving}
        onClick={onSaveButtonClick}
      />
    );
  }

  function publishControl() {
    return (
      <>
        <div className={classes.divider} />
        <Button
          type="button"
          primary
          className={classes.actionButton}
          disabled={buttonsDisabled}
          loading={savingStatus}
          onClick={onPublishButtonClick}
        >
          Publish
        </Button>
      </>
    );
  }

  return (
    <div className={classes.formControls}>
      {showStatus && statusText()}
      {showSaveControl && saveControl()}
      {showPublishControl && publishControl()}
      {showOptionMenu && (
        <Dropdown
          pointing="top right"
          icon={null}
          disabled={buttonsDisabled}
          trigger={
            <IconButton
              type="button"
              icon="ellipsis vertical"
              size="huge"
              disabled={buttonsDisabled}
            />
          }
          options={eventMenuOptions}
        />
      )}
      <IconButton
        type="button"
        icon="close"
        size="huge"
        onClick={() => history.push("/")}
      />
      <SaveEventModal
        open={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
        onAccept={onSaveModalAccept}
      />
      <PublishEventModal
        event={event}
        open={publishModalOpen}
        onClose={() => setPublishModalOpen(false)}
        onAccept={onPublishModalAccept}
      />
      <CancelEventModal
        event={event}
        registrants={registrants}
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onAccept={onCancelModalAccept}
      />
      <DeleteEventModal
        event={event}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onAccept={() => onDeleteModalAccept()}
      />
    </div>
  );
}

export default EventFormControls;
