import React from "react";
import { Input } from "../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../shared/form/support";
import { requiredNotBlank } from "@sempra-event-registration/common";
import { defaultNameMaxLength } from "../../../../support/eventFormDefaults";

const maxLength = defaultNameMaxLength;

function EventContactName({ control, disabled, errors }) {
  return (
    <Input
      name="contactName"
      label="Name"
      type="text"
      control={control}
      disabled={disabled}
      rules={{
        validate: requiredNotBlank("Required"),
        maxLength: {
          value: maxLength,
          message: `Cannot exceed ${maxLength} characters`,
        },
      }}
      error={selectError(() => errors.contactName)}
    />
  );
}

export default EventContactName;
