import React from "react";
import { Button } from "semantic-ui-react";

function CreateAdminButton({ onClick }) {
  return (
    <Button primary onClick={() => onClick()}>
      Add Admin
    </Button>
  );
}

export default CreateAdminButton;
