import React from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./EventDate.module.css";
import ReactDatePicker from "react-datepicker";
import { selectError } from "../../../../../../../shared/form/support";
import { FormField } from "../../../../../../../shared/semantic-form";
import { startOfTomorrow, subDays } from "date-fns";

function EventDate({ control, disabled, errors }) {
  const { watch, setValue } = useFormContext();
  // Note: this value gets pulled at render, which is why in the context of this comp it isthe current value before change event
  const previousDate = watch("date");

  return (
    <Controller
      name="date"
      control={control}
      rules={{
        required: "Required",
      }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <FormField
            label="Date of Event"
            disabled={disabled}
            error={selectError(() => errors.date)}
          >
            <ReactDatePicker
              wrapperClassName={styles.wrapper}
              dateFormat="MMMM d, yyyy"
              selected={value}
              minDate={startOfTomorrow()}
              onChange={(newEventDate) => {
                // RULE:  If a user changes the event's date to an earlier date, the registration deadline should
                // automatically be updated to one day before the newly selected date.  Otherwise, the registration
                // deadline shouldn't change (because the event date is after the regisration deadline).
                if (newEventDate < previousDate || !previousDate) {
                  const defaultRegistrationDeadline = subDays(newEventDate, 1);
                  setValue(
                    "registrationDeadline",
                    defaultRegistrationDeadline,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    },
                  );
                }

                onChange(newEventDate);
              }}
              {...{ onBlur, disabled }}
            />
          </FormField>
        );
      }}
    />
  );
}

EventDate.prototype = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default EventDate;
