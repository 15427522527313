import { useRemoteConfig, map } from "@sempra-event-registration/common";
import { isRunningLocally } from "../development";
import { getValue } from "firebase/remote-config";

const defaultInternalRegistrationBaseUrl = "http://localhost:3001";
const defaultExternalRegistrationBaseUrl = "http://localhost:3002";

export function useAppConfig() {
  const runningLocally = isRunningLocally();
  return map(useRemoteConfig(), (remoteConfig) => ({
    sandboxEnabled: getValue(remoteConfig, "sandboxEnabled").asBoolean(),
    externalRegistrationBaseUrl: runningLocally
      ? defaultExternalRegistrationBaseUrl
      : (getValue(remoteConfig, "externalRegistrationBaseUrl").asString() ??
        defaultExternalRegistrationBaseUrl),
    internalRegistrationBaseUrl: runningLocally
      ? defaultInternalRegistrationBaseUrl
      : (getValue(remoteConfig, "internalRegistrationBaseUrl").asString() ??
        defaultInternalRegistrationBaseUrl),
  }));
}
