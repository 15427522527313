/**
 * Group the collection of registrants into two groups:
 * <ul>
 *   <li><strong>Yes:</strong> If the registrant responded affirmative to "represented by a union"
 *   <li><strong>No:</strong> If the registrant responded negative to "represented by a union"
 * </ul>
 * @example
 *   // example result
 *   [
 *     { response: "yes" , count: 2 },
 *     { response: "no", count: 1 }
 *   ]
 *
 * @param registrants {Array} The collection of registered users from the registrants collection of an event
 * @returns {Object} an object containing the counts of "yes" and "no" responses
 */
export default function groupRegistrantsByUnionRepresentation(registrants) {
  let yes = 0;
  let no = 0;

  registrants.forEach((registrant) =>
    registrant.representedByUnion ? yes++ : no++
  );

  return [
    { response: "yes", count: yes },
    { response: "no", count: no },
  ];
}
