import React from "react";
import { Dropdown } from "semantic-ui-react";
import IconButton from "../../IconButton/IconButton";
import classes from "./TableRowControls.module.css";

function TableRowControls({ options, ...rest }) {
  return (
    <div {...rest}>
      <Dropdown
        className={classes.dropdown}
        pointing="top right"
        icon={null}
        trigger={
          <IconButton
            className={classes.trigger}
            type="button"
            icon="ellipsis vertical"
            size="huge"
          />
        }
        options={options}
      />
    </div>
  );
}

export default TableRowControls;
