import React from "react";
import { FormProvider, useForm } from "react-hook-form";

function WorkshopForm({ defaultValues, disabled, children }) {
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  // Form validation is manually invoked therefore handleSubmit is not used
  return (
    <div className={disabled ? "readonly" : ""}>
      <FormProvider {...form}>{children}</FormProvider>
    </div>
  );
}

export default WorkshopForm;
