import { useCallback, useEffect, useReducer } from "react";
import { GOOGLE_CALLBACK_NAME } from "../constants/google";
import addGoogleMapsAPIScript from "./addGoogleMapsAPIScript";

import {
  addGoogleMapsAuthenticationErrorListener,
  removeGoogleMapsAuthenticationErrorListener,
} from "./authListeners";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "AUTHENTICATION_ERROR":
      return { ...state, authenticationError: true };
    case "INITIALIZED":
      const { autoCompleteService, OK, placesService } = payload;
      return {
        ...state,
        autoCompleteService,
        OK,
        placesService,
      };
    default:
      return state;
  }
};

export default function useGooglePlacesService() {
  const [state, dispatch] = useReducer(reducer, {
    authenticationError: false,
    autoCompleteService: null,
    OK: null,
    placesService: null,
  });
  const { authenticationError, autoCompleteService, OK, placesService } = state;

  const initializeServices = useCallback(() => {
    if (!window.google) {
      throw new Error(
        "[useGooglePlacesService]: Google Maps JavaScript API library must be loaded."
      );
    }
    if (!window.google.maps.places) {
      throw new Error(
        "[useGooglePlacesService]: Google Maps Places library must be loaded."
      );
    }

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const autoCompleteService = new window.google.maps.places.AutocompleteService();
    const OK = window.google.maps.places.PlacesServiceStatus.OK;

    dispatch({
      type: "INITIALIZED",
      payload: { autoCompleteService, OK, placesService },
    });
  }, [dispatch]);

  const onAuthenticationError = useCallback(() => {
    dispatch({ type: "AUTHENTICATION_ERROR" });
  }, [dispatch]);

  useEffect(() => {
    addGoogleMapsAuthenticationErrorListener(onAuthenticationError);
    addGoogleMapsAPIScript();
    if (!window.google) {
      window[GOOGLE_CALLBACK_NAME] = initializeServices;
    } else {
      initializeServices();
    }
    return () => {
      if (window[GOOGLE_CALLBACK_NAME]) {
        delete window[GOOGLE_CALLBACK_NAME];
      }
      removeGoogleMapsAuthenticationErrorListener(onAuthenticationError);
    };
  }, [initializeServices, onAuthenticationError]);

  const getPlacePredictions = useCallback(
    (searchQuery, callback) => {
      autoCompleteService.getPlacePredictions(
        {
          componentRestrictions: { country: "us" },
          location: { lat: () => 0, lng: () => 0 },
          radius: 1,
          types: ["geocode"],
          input: searchQuery,
        },
        callback
      );
    },
    [autoCompleteService]
  );

  const getPlaceDetails = useCallback(
    (placeId, callback) => {
      const request = {
        placeId,
        fields: ["address_components", "geometry", "types"],
      };
      placesService.getDetails(request, callback);
    },
    [placesService]
  );

  return { authenticationError, getPlaceDetails, getPlacePredictions, OK };
}
