import { useState, useEffect } from "react";
import {
  collection,
  firestore,
  getDocs,
} from "@sempra-event-registration/common";
import { convertFromRaw, EditorState } from "draft-js";

const useDefaultMessages = () => {
  const [defaultMessages, setDefaultMessages] = useState({});

  useEffect(() => {
    const messagesCollection = collection(firestore, "default_messages");

    const fetchMessages = async () => {
      try {
        const snapshot = await getDocs(messagesCollection);
        const messages = {};
        snapshot.forEach((doc) => {
          const data = doc.data();
          try {
            messages[doc.id] = {
              id: doc.id,
              label: data.label,
              message: EditorState.createWithContent(
                convertFromRaw(data.message),
              ),
            };
          } catch (error) {
            console.error(`Error processing message ID: ${doc.id}`, error);
          }
        });
        setDefaultMessages(messages);
      } catch (error) {
        console.error("Error fetching default messages:", error);
      }
    };

    fetchMessages();
  }, []);
  return defaultMessages;
};

export default useDefaultMessages;
