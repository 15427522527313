import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { RichTextEditor, selectError } from "../../../../../../../shared/form";
import { FormField } from "../../../../../../../shared/semantic-form";
import { requiredRichText } from "../../../../../../support/requiredRichText";
import styles from "./EventDescription.module.css";
import { EventContext } from "../../../../context/EventContext";

function EventDescription({
  disabled,
  description,
  setDescription,
  triedToPublish,
}) {
  const { value: event } = useContext(EventContext);

  const { register, setValue } = useFormContext();
  const error =
    event.status !== "draft" || triedToPublish
      ? requiredRichText()(description)
      : null;

  return (
    <FormField
      label="Description"
      disabled={disabled}
      error={selectError(() => {
        return error ? { message: error } : null;
      })}
    >
      {/* This is here to allow forms to know if it's been edited - work around*/}
      <input type="hidden" {...register("descriptionEdited")} />
      <RichTextEditor
        {...{ disabled }}
        onChange={(value) => {
          // setting value to tell the form that it's dirty. Hack for getting the ability to save when you've only edited the description.
          setValue("descriptionEdited", new Date().toISOString(), {
            shouldDirty: true,
          });
          setDescription(value);
        }}
        value={description}
        placeholder="Enter description text, and use the toolbar above to format it..."
        className={error === null ? "" : styles.eventDescriptionError}
      />
    </FormField>
  );
}

export default EventDescription;
