import React, { useEffect } from "react";
import { Select } from "../../../../../../../../../shared/react-hook-form";
import { useFormContext } from "react-hook-form";

function findFirstAvailableSubgroup(groups = [], selectedGroupId) {
  if (!groups.length) return "";
  const { subgroups = [] } = groups.find((x) => x.id === selectedGroupId) || {};
  const firstOpenSubgroup = subgroups.find(
    (subgroup) => subgroup.registrationCount < subgroup.capacity,
  );
  return firstOpenSubgroup?.id || "";
}

function RegistrationGroup({ disabled, event, registration }) {
  const { control, setValue, watch, formState: { errors } } = useFormContext();
  const { groups = [{}] } = event;
  const selectedGroupId = watch("group");

  const registeredGroupId = registration.group?.id || "";
  const registeredSubgroupId = registration.subgroup?.id || "";

  function setFormValue(formField, value) {
    setValue(formField, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  useEffect(() => {
    // If a user hasn't selected a group, pick the first one in the list.
    // If there are no groups, set the group selection to an empty string.
    if (!selectedGroupId) {
      setFormValue("group", groups[0]?.id || "");
    }
    // If a user is already registered for a sub group, preserve their selection.
    // Otherwise, find the first available sub group.
    const newSubGroupId =
      !!registeredSubgroupId && registeredGroupId === selectedGroupId
        ? registeredSubgroupId
        : findFirstAvailableSubgroup(groups, selectedGroupId);

    setFormValue("subgroup", newSubGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId]);

  return (
    <Select
      control={control}
      disabled={disabled}
      name="group"
      label="Group"
      rules={{
        required: "Required",
      }}
      error={errors.group}
      options={groups.map((group) => {
        const currentlyRegistered =
          registeredGroupId === group.id ? " (Registered)" : "";
        return {
          key: group.id,
          text: group.name + currentlyRegistered,
          value: group.id,
        };
      })}
    />
  );
}

export default RegistrationGroup;
