import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { RichTextEditor, selectError } from "../../../../../../../shared/form";
import { FormField } from "../../../../../../../shared/semantic-form";
import styles from "./EventMessageText.module.css";
import { EventContext } from "../../../../context/EventContext";
import EditorActionButton from "../../../../../../../shared/form/component/RichTextEditor/controls/EditorActionButton";

function EventMessageText({
  disabled,
  triedToPublish,
  currentMessageId,
  customMessages,
  updateCustomMessages,
  revertToDefault,
}) {
  const { value: event } = useContext(EventContext);

  const { register, setValue } = useFormContext();
  const error =
    event.status !== "draft" || triedToPublish
      ? checkForError(customMessages)
      : null;

  return (
    <FormField
      disabled={disabled}
      error={selectError(() => {
        return error ? { message: error } : null;
      })}
    >
      {/* This is here to allow forms to know if it's been edited - work around*/}
      <input
        type="hidden"
        {...register("customMessagesEdited")}
        name="customMessagesEdited"
      />
      <RichTextEditor
        {...{ disabled }}
        onChange={(value) => {
          // setting value to tell the form that it's dirty. Hack for getting the ability to save when you've only edited the message.
          setValue(`customMessagesEdited`, new Date().toISOString(), {
            shouldDirty: true,
          });
          updateCustomMessages(value);
        }}
        value={customMessages[currentMessageId]?.message}
        placeholder="Select an email message from the dropdown to edit it."
        className={error === null ? "" : styles.eventMessageError}
        additionalControls={[
          <EditorActionButton
            onClick={revertToDefault}
            disabled={disabled}
            label="Revert to Original Message"
            key="revertToOriginalButton"
          />,
        ]}
      />
    </FormField>
  );
}

function checkForError(customMessages) {
  const emptyMessages = [];
  for (const id in customMessages) {
    const messageContent = customMessages[id].message.getCurrentContent();
    if (
      !messageContent.hasText() ||
      messageContent.getPlainText("\u0001").trim().length === 0
    )
      emptyMessages.push(customMessages[id].label);
  }
  return emptyMessages.length === 0
    ? null
    : `One or more email messages are empty: ${emptyMessages.join(", ")}.`;
}

export default EventMessageText;
