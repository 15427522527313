export const rolesToDisplayNamesMap = {
  sdge_external_event_admin: "SDG&E External Event Administrator",
  sdge_external_event_admin_supervisor:
    "SDG&E External Event Administrators Supervisor",
  internal_event_admin: "Internal Event Administrator",
  internal_event_admin_supervisor: "Internal Event Administrators Supervisor",
  sdge_employee_event_admin: "SDG&E Employee Event Administrator",
  sdge_employee_event_admin_supervisor:
    "SDG&E Employee Event Administrators Supervisor",
};

export const roles = [
  "sdge_external_event_admin",
  "sdge_external_event_admin_supervisor",
  "internal_event_admin",
  "internal_event_admin_supervisor",
  "sdge_employee_event_admin",
  "sdge_employee_event_admin_supervisor",
];
