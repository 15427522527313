import { areIntervalsOverlapping } from "date-fns";

// Assumption here is that the range to compare is not in the ranges collection.
export default function doesNotOverlapOtherDateRanges(
  { startTime: start, endTime: end }, // {startTime, endTime} - start end end of main interval we are checking
  ranges, // [{startTime, endTime}]
  message = "can't overlap other"
) {
  const isOverlapping = ranges.some(({ startTime, endTime }) => {
    return areIntervalsOverlapping(
      { start: startTime, end: endTime },
      { start, end }
    );
  });
  return isOverlapping ? message : null;
}
