import React, { useState } from "react";
import classes from "./EventMessagesSection.module.css";
import EventMessageText from "../../field/EventMessageText/EventMessageText";
import EventMessageSelector from "../../field/EventMessageSelector/EventMessageSelector";
import FieldContainer from "../../component/FieldContainer/FieldContainer";

function EventMessagesSection({
  fieldProps,
  customMessages,
  setCustomMessages,
  defaultMessages,
  triedToPublish,
  editing,
  ...rest
}) {
  const [editorDisabled, setEditorDisabled] = useState(true);
  const [currentMessageId, setCurrentMessageId] = useState(null);

  const buildDropDown = (messages) => {
    const dropdownItems = [];
    for (const id in messages) {
      dropdownItems.push({
        key: id,
        text: messages[id].label,
        value: id,
      });
    }
    return dropdownItems.sort((first, second) =>
      first.text < second.text ? -1 : first.text > second.text ? 1 : 0
    );
  };

  const onChangeMessageSelection = (value) => {
    setCurrentMessageId(value);
    if (value) setEditorDisabled(false);
  };

  const revertToDefault = () => {
    if (!currentMessageId) return;
    updateCustomMessages(defaultMessages[currentMessageId].message);
  };

  const updateCustomMessages = (messageValue) => {
    setCustomMessages({
      ...customMessages,
      [currentMessageId]: {
        ...customMessages[currentMessageId],
        message: messageValue,
      },
    });
  };

  return (
    <div
      testprops={{
        functions: [onChangeMessageSelection, revertToDefault],
        editorDisabled,
        currentMessageId,
      }}
      {...rest}
    >
      <FieldContainer className={classes.eventMessageText}>
        <EventMessageSelector
          {...{
            ...fieldProps,
            onChangeMessageSelection,
            options: buildDropDown(customMessages),
          }}
        />
      </FieldContainer>
      <EventMessageText
        {...{
          ...fieldProps,
          triedToPublish,
          disabled: editorDisabled,
          currentMessageId,
          customMessages,
          updateCustomMessages,
          revertToDefault,
        }}
      />
    </div>
  );
}

export default EventMessagesSection;
