const findAddressComponentByType = (addressComponents, desiredTypes) =>
  addressComponents.find((component) => {
    return !!component.types.find((type) => {
      return desiredTypes.find((desiredType) => {
        return type === desiredType;
      });
    });
  }) || { long_name: "", short_name: "" };

export default function googlePlaceToLocation(place, placeId) {
  const {
    address_components,
    geometry: {
      location: { lat, lng },
    },
  } = place;

  // addressLine1
  const number = findAddressComponentByType(address_components, [
    "street_number",
  ]);
  const street = findAddressComponentByType(address_components, ["route"]);
  const addressLine1 =
    number.long_name || street.short_name
      ? `${number.long_name} ${street.short_name}`.trim()
      : null;

  // city
  const locality = findAddressComponentByType(address_components, ["locality"])
    .long_name;
  const sublocality = findAddressComponentByType(address_components, [
    "sublocality",
  ]).long_name;
  const city = locality || sublocality;

  // state
  const state = findAddressComponentByType(address_components, [
    "administrative_area_level_1",
  ]).short_name;

  // zipCode
  const zipCode = findAddressComponentByType(address_components, [
    "postal_code",
  ]).long_name;

  return {
    googlePlaceId: placeId,
    addressLine1,
    city,
    latitude: lat(),
    longitude: lng(),
    state,
    zipCode,
  };
}
