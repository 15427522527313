import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import AdminUserTypeahead from "../AdminUserTypeahead/AdminUserTypeahead";
import {
  comparing,
  emptyResource,
  errorResource,
  functions,
  isBlank,
  isEmail,
  loadedResource,
  loadingResource,
  stringComparator,
  useLoadedUser,
} from "@sempra-event-registration/common";
import CheckboxList from "../CheckboxList/CheckboxList";
import {
  roles,
  rolesToDisplayNamesMap,
} from "../../support/rolesToDisplayNamesMap";
import {
  adminGroups,
  adminGroupsToDisplayNamesMap,
} from "../../support/adminGroupsToDisplayNamesMap";
import classes from "./AdminsFormFields.module.css";
import { Label } from "semantic-ui-react";
import { httpsCallable } from "firebase/functions";

/**
 * Call to the Firebase cloud function that will query the Microsoft Graph API for the specified user.
 *
 * @type {firebase.functions.HttpsCallable}
 */

const findUserById = httpsCallable(functions, "findUserById");

/**
 * Determine if the selected user is already a member of one of the admin roles.  If they are, return a message
 * indicating they already exist.
 * <p>
 *   This validator is intended for use with the <code>validate</code> setup for react-hook-form.  When the user selects
 *   a result from the type-ahead control (i.e. the {@link AdminUserTypeahead#onChange} event fires), this validator
 *   will be triggered.
 * </p>
 *
 * @param users {Array} the collection of internal users that are members of at least one admin role.
 * @returns {function(*=): (undefined|string|any)} A function that will determine if the selected user already exists
 * in the collection of admin-level users.  If true, a string with an error message is returned; otherwise undefined is
 * returned (indicating to react-hook-form the validation was successful)
 */
function userIsAlreadyAdmin(users) {
  return (value) => {
    if (isBlank(value) || !isEmail(value)) {
      return;
    }

    // If the user is already an admin-level user (i.e. they are assigned to at least one "_admin" role), they cannot be
    // added again.  The selected user account should be edited instead.
    const existingAdminUser = users.find((x) => value === x.id);
    if (existingAdminUser != null) {
      return `${existingAdminUser.id} is already an administrative-level user.  To change their roles/groups, close this screen and choose "Edit" for this user.`;
    }

    // Explicitly return undefined here to tell react-hook-form the validation passed.
    return undefined;
  };
}

/**
 * Find an Active Directory user using the Microsoft Graph API to collect the user's information.
 *
 * @param id The identifier of the user to find
 * @returns {Promise<*>} A promise that will resolve to the user data from the Microsoft Graph API call
 */
function findInternalResourceById(id) {
  return findUserById({ id }).then((response) => response.data);
}

function AdminFormFields({ adminUsers, setAdminUser, isNew }) {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();
  const user = useLoadedUser();
  const selectedRoles = watch("roles");

  // Setup async loaded internal resource details
  const [graphApiFetchStatus, setGraphApiFetchStatus] =
    useState(emptyResource());

  // A supervisor-level user should only be able to assign admins to roles they supervise.  For example, if a supervisor
  // is a member of he sdge_external_event_admin_supervisor and internal_event_admin_supervisor roles, they should only
  // be able to add/remove admin user to the sdge_external_event_admin or internal_event_admin roles.
  const supervisedRoles = user.roles
    .filter((role) => role.endsWith("_admin_supervisor"))
    .map((supervisorRole) =>
      supervisorRole.replace("_admin_supervisor", "_admin"),
    );

  // Use the supervisedRoles collection to limit the role options that show up on the input modal.
  const roleOptions = roles
    // .filter((role) => supervisedRoles.includes(role))
    .map((role) => ({
      value: role,
      label: rolesToDisplayNamesMap[role],
    }))
    .sort(comparing(stringComparator, (x) => x.label));

  const adminGroupOptions = adminGroups
    .map((adminGroup) => ({
      value: adminGroup,
      label: adminGroupsToDisplayNamesMap[adminGroup],
    }))
    .sort(comparing(stringComparator, (x) => x.label));

  return (
    <div>
      {isNew && (
        <AdminUserTypeahead
          control={control}
          errors={errors}
          loading={graphApiFetchStatus.status === "loading"}
          userIsAlreadyAdminValidator={userIsAlreadyAdmin(adminUsers)}
          onChange={async (selectedTypeAheadResult) => {
            // Capture the user's selection
            setValue("id", selectedTypeAheadResult.id, {
              shouldDirty: true,
              shouldValidate: true,
            });

            // Validate the user's input
            const valid = await trigger("id");
            if (!valid) {
              return;
            }

            // Fetch the full user from the Microsoft Graph API
            setGraphApiFetchStatus(loadingResource());

            await findInternalResourceById(selectedTypeAheadResult.id)
              .then((resource) => {
                setGraphApiFetchStatus(loadedResource());
                setAdminUser({ ...resource });
              })
              .catch((error) => {
                console.error(
                  `Error attempting to fetch ${selectedTypeAheadResult} via the Microsoft Graph API: `,
                  error,
                );
                setGraphApiFetchStatus(errorResource(error));
              });
          }}
        />
      )}
      <div>
        <h3>Admin Roles</h3>
        <CheckboxList
          name="roles"
          options={roleOptions}
          className={classes.checkboxList}
          rules={{
            validate: (value) =>
              value.length === 0 ? "Choose at least one role" : undefined,
          }}
        />
        {errors.roles != null && (
          <Label basic pointing="above" className={classes.errorMessage}>
            {errors.roles.message}
          </Label>
        )}
      </div>
      {selectedRoles.includes("sdge_external_event_admin") && (
        <div>
          <h3>Admin Groups</h3>
          <CheckboxList
            name="groups"
            options={adminGroupOptions}
            className={classes.checkboxList}
          />
        </div>
      )}
    </div>
  );
}

export default AdminFormFields;
