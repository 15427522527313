import React, { useContext } from "react";
import { parse } from "query-string";
import { Navigate, Route, useMatch, Routes } from "react-router-dom";
import {
  useLoadedUser,
  compose,
  Error,
} from "@sempra-event-registration/common";
import { useEvent } from "./hook/useEvent";
import { EventContext } from "./context/EventContext";
import EventFormPage from "./page/EventFormPage/EventFormPage";
import EventRegistrationPage from "./page/EventRegistrationPage/EventRegistrationPage";
import EventReportsPage from "./page/EventReportsPage/EventReportsPage";
import Loader from "../../../shared/ui/component/Loader/Loader";

function RoutesComp({ user, path }) {
  const { source: sourceId } = parse(window.location.search);
  const resources = compose(useContext(EventContext), useEvent(sourceId, user));

  if (resources.status === "loading" || resources.status === "empty") {
    return <Loader />;
  }
  if (resources.status === "error") {
    return <Error message={resources.error.message} />;
  }

  const [event, sourceEvent] = resources.value;

  return (
    <Routes>
      <Route
        path="/"
        element={<EventFormPage {...{ event, sourceEvent }} />}
      ></Route>
      <Route path={`/registration`} element={<EventRegistrationPage />} />
      <Route path={`/reports`} element={<EventReportsPage />} />
      <Route path="*" element={<Navigate to={path} replace />} />
    </Routes>
  );
}

function EventPage({ match }) {
  const user = useLoadedUser();
  const {
    pathname,
    params: { eventId },
  } = useMatch("/events/:eventId/*");
  const event = useEvent(eventId, user);
  return (
    <EventContext.Provider value={event}>
      <RoutesComp user={user} path={pathname} />
    </EventContext.Provider>
  );
}

export default EventPage;
