import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { timeOf } from "@sempra-event-registration/common";

const notNull = (value) => value != null;

export function totalSubgroupCapacities(groups = []) {
  return groups.reduce(
    (total, group) =>
      total +
      (group.subgroups || []).reduce(
        (subtotal, subgroup) =>
          subtotal + Number.parseInt(subgroup.capacity || 0),
        0
      ),
    0
  );
}

export function locationToFormValues(location) {
  const {
    name: locationName,
    additionalDirections,
    address = {},
    rooms = [],
    useSubgroupCapacities = false,
    ...restLocation
  } = location;

  const { line1: addressLine1, line2: addressLine2, ...restAddress } = address;

  const formAdditionalDirections = additionalDirections
    ? EditorState.createWithContent(convertFromRaw(additionalDirections))
    : EditorState.createEmpty();

  return {
    locationName,
    ...restLocation,
    addressLine1,
    addressLine2,
    ...restAddress,
    additionalDirections: formAdditionalDirections,
    useSubgroupCapacities,
    rooms,
  };
}

export function documentToFormValues(document) {
  const { name, fileName, ...file } = document;
  return {
    name,
    file: {
      name: fileName,
      ...file,
    },
  };
}

export function mealToFormValues(meal) {
  const { startTime, endTime, startsAt, endsAt, options = [], ...rest } = meal;
  return {
    startTime: startTime || startsAt,
    endTime: endTime || endsAt,
    options: options.map(mealToFormValues),
    ...rest,
  };
}

export function groupToFormValues(group) {
  const { subgroups = [], ...rest } = group;
  return {
    subgroups: subgroups.map(groupToFormValues),
    ...rest,
  };
}

export function customMessagesToFormValues(customMessages) {
  const messages = {};
  for (const id in customMessages) {
    messages[id] = {
      id,
      label: customMessages[id].label,
      message: EditorState.createWithContent(
        convertFromRaw(customMessages[id].message)
      ),
    };
  }
  return messages;
}

export function eventToFormValues(event) {
  // The event ID, type and createdBy are intentionally stripped out of the form values here
  // This facilitates the clone event functionality and removes properties
  // for which there are no corresponding fields
  const {
    id,
    type,
    createdBy,
    description,
    location = {},
    contact = {},
    meals = [],
    documents = [],
    groups = [],
    workshops = [],
    customMessages = {},
    // omit these fields as they are not used in the form (yet)
    status,
    startsAt,
    endsAt,
    // pass along the rest as-is
    ...restEvent
  } = event;

  const formDescription = description
    ? EditorState.createWithContent(convertFromRaw(description))
    : EditorState.createEmpty();

  const {
    name: contactName,
    email: contactEmail,
    phone: contactPhone,
  } = contact;

  return {
    description: formDescription,
    ...restEvent,
    ...locationToFormValues(location),
    contactName,
    contactEmail,
    contactPhone,
    meals: meals.map(mealToFormValues),
    groups: groups.map(groupToFormValues),
    documents: documents.map(documentToFormValues),
    workshops,
    customMessages: customMessagesToFormValues(customMessages),
  };
}

/**
 * Get the "startsAt" and "endsAt" date/time values for an event.
 * <p>
 *     In the UI a user can only select the event date, start time, and end time.  To make doing calendar/date
 *     arithmetic on events easier, we will store the "startsAt" and "endsAt" date.  Since the user can only choose one
 *     date, The day, month and year of the "startsAt" and "endsAt" dates will be the same, but the times will be
 *     different.
 * </p>
 * <p>
 *     In order to properly hydrate the Start Time and End Time controls on the user interface, the Start Time and End
 *     Time are stored as a string in their own field.
 * </p>
 * <p>
 *     Because it is possible for a user to save an event without a start date, start time and/or end time, this
 *     function computes the startsAt and endsAt to the best of its ability with the data it has.  The result is an
 *     object that has:
 *     <ul>
 *         <li>the start date</li>
 *         <li>the start time</li>
 *         <li>the end date</li>
 *         <li>the end time</li>
 *     </ul>
 *     The intent of this object is to merge it into the other values passed in from the event creation/editing form.
 *     See the {@code formValuesToEvent} function for an example.
 * <p>
 * <p>
 *     <strong>NOTE:</strong> This function is exported to facilitate testing.
 * </p>
 * @param date The event date selected by the user.  Can be nullish.
 * @param startTime The event's start time (as selected by the user).  Can be nullish.
 * @param endTime The event's end time (as selected by the user).  Can be nullish.
 * @returns Object an object containing the startsAt, endsAt, startTime and endTime.
 */
export function getEventDateAndTimes(date, startTime, endTime) {
  const eventDateAndTimes = {
    date,
    startTime: startTime ? timeOf(startTime) : startTime,
    endTime: endTime ? timeOf(endTime) : endTime,
  };

  if (date != null) {
    if (startTime != null) {
      eventDateAndTimes.startsAt = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        startTime.getHours(),
        startTime.getMinutes()
      );
    }

    if (endTime != null) {
      eventDateAndTimes.endsAt = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        endTime.getHours(),
        endTime.getMinutes()
      );
    }
  }

  return eventDateAndTimes;
}

export function formValuesToMeal(values) {
  const { startTime, endTime, options = [], ...rest } = values;
  return {
    startTime: startTime ? timeOf(startTime) : null,
    endTime: endTime ? timeOf(endTime) : null,
    options: options.filter(notNull),
    ...rest,
  };
}

function formValuesToWorkshop(values) {
  const { startTime, endTime, ...rest } = values;
  return {
    startTime: timeOf(startTime),
    endTime: timeOf(endTime),
    ...rest,
  };
}

function formValuesToCustomMessages(customMessages) {
  const messages = {};
  for (const id in customMessages) {
    const message = customMessages[id];
    const rawMessage = convertToRaw(message.message.getCurrentContent());
    messages[id] = {
      label: message.label,
      message: rawMessage,
      messageHtml: stateToHTML(message.message.getCurrentContent()),
      messageText: rawMessage.blocks.map((block) => block.text),
    };
  }
  return messages;
}

export function formValuesToEvent(values) {
  const {
    additionalDirections,
    addressLine1,
    addressLine2,
    capacity,
    city,
    contactName,
    contactEmail,
    contactPhone,
    customMessages,
    description,
    endTime,
    googlePlaceId,
    latitude,
    longitude,
    locationName,
    rooms,
    date,
    startTime,
    state,
    zipCode,
    meals,
    groups,
    documents,
    workshops,
    securityGroups = [],
    useSubgroupCapacities,
    ...rest
  } = values;

  const eventDateAndTimes = getEventDateAndTimes(date, startTime, endTime);
  const location = {
    name: locationName,
    address: {
      line1: addressLine1,
      line2: addressLine2,
      city,
      state,
      zipCode,
      latitude,
      longitude,
      googlePlaceId,
    },
    useSubgroupCapacities,
    capacity: useSubgroupCapacities
      ? totalSubgroupCapacities(groups)
      : capacity,
    additionalDirections: convertToRaw(
      additionalDirections.getCurrentContent()
    ),
    additionalDirectionsHtml: stateToHTML(
      additionalDirections.getCurrentContent()
    ),
    rooms: (rooms || []).filter(notNull),
  };

  const contact = {
    name: contactName,
    email: contactEmail,
    phone: contactPhone,
  };

  return {
    ...rest,
    ...eventDateAndTimes,
    location,
    description: convertToRaw(description.getCurrentContent()),
    descriptionHtml: stateToHTML(description.getCurrentContent()),
    contact,
    meals: (meals || []).filter(notNull).map(formValuesToMeal),
    groups: groups || [],
    documents: (documents || []).filter(notNull),
    workshops: (workshops || [])
      .filter(notNull)
      // Make sure the room assigned to the workshop still exists and was not deleted
      // If deleted, remove the workshop from the collection
      .filter((x) => rooms.some((y) => y.id === x.room?.id))
      .map(formValuesToWorkshop),
    securityGroups,
    customMessages: formValuesToCustomMessages(customMessages),
  };
}
