import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { selectError } from "../../../../../../../shared/form/support";
import { FormField } from "../../../../../../../shared/semantic-form";
import { addMinutes, differenceInMinutes } from "date-fns";
import {
  defaultEventDurationInMinutes,
  minimumEventDurationInMinutes,
} from "../../../../support/eventFormDefaults";
import { timeIsBefore } from "@sempra-event-registration/common";
import { verifyMinimumTimespan } from "@sempra-event-registration/common/src/form-validation";

function EventStartTime({ control, disabled, errors }) {
  const { watch, clearErrors, setValue, trigger } = useFormContext();
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  useEffect(() => {
    if (!startTime) return;
    trigger("startTime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  const adjustEndTime = (currentStartTime) => {
    const currentEventDuration = differenceInMinutes(endTime, startTime);
    const minDuration =
      currentEventDuration >= defaultEventDurationInMinutes
        ? currentEventDuration
        : defaultEventDurationInMinutes;
    const minEndTime = addMinutes(currentStartTime, minDuration);
    // Check if endtime is less than 30 from start, if it is, change it.
    if (!endTime || endTime < minEndTime) {
      setValue("endTime", minEndTime, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  return (
    <Controller
      {...{ control }}
      name="startTime"
      rules={{
        required: "Required",
        validate: (startTime) => {
          clearErrors("startTime");
          clearErrors("endTime");

          const invalidTimeMessage = timeIsBefore(
            startTime,
            endTime,
            "Start time must be before end time",
          );

          if (invalidTimeMessage) {
            return invalidTimeMessage;
          }

          return verifyMinimumTimespan(
            startTime,
            endTime,
            minimumEventDurationInMinutes,
            `An event must be at least ${minimumEventDurationInMinutes} minutes long`,
          );
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <FormField
            label="Start Time"
            disabled={disabled}
            error={selectError(() => errors.startTime)}
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm aa"
              selected={value}
              timeCaption="Start Time"
              onChange={(value) => {
                adjustEndTime(value);
                onChange(value);
              }}
              {...{ onBlur, disabled }}
            />
          </FormField>
        );
      }}
    />
  );
}

EventStartTime.prototype = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default EventStartTime;
