import {
  eventTypes,
  doc,
  collection,
  firestore,
  firestoreDocumentQuery,
} from "@sempra-event-registration/common";
import { createEvent } from "@sempra-event-registration/common/src/event";

function eventDocumentToEvent(document) {
  const data = document.data();
  const capacity = parseInt(data.location?.capacity);
  const full =
    !isNaN(capacity) &&
    capacity > 0 &&
    data.reservationCount >= data.location?.capacity;

  return {
    ...data,
    updatedAt: data.updatedAt?.toDate(),
    createdAt: data.createdAt?.toDate(),
    id: document.id,
    date: data.date?.toDate() || data.startsAt?.toDate(),
    startTime: data.startTime?.toDate(),
    endTime: data.endTime?.toDate(),
    startsAt: data.startsAt?.toDate(),
    endsAt: data.endsAt?.toDate(),
    registrationDeadline: data.registrationDeadline?.toDate(),
    meals: (data.meals || []).map((meal) => ({
      ...meal,
      startTime: meal.startTime?.toDate() || meal.startsAt?.toDate(),
      endTime: meal.endTime?.toDate() || meal.endsAt?.toDate(),
    })),
    workshops: (data.workshops || []).map((workshop) => ({
      ...workshop,
      startTime: workshop.startTime?.toDate(),
      endTime: workshop.endTime?.toDate(),
    })),
    full,
  };
}

export const useEvent = firestoreDocumentQuery((id) =>
  doc(collection(firestore, "events"), id),
)
  .condition(
    (id) =>
      // empty source event
      id != null &&
      // new event of type
      !eventTypes.includes(id),
  )
  .defaultValue((type, user) =>
    type != null
      ? // new event of type
        createEvent(type, user)
      : // empty source event
        {},
  )
  .map(eventDocumentToEvent)
  .build();
