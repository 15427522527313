import React from "react";
import { Select } from "../../../../../../../../../shared/react-hook-form";
import { useFormContext } from "react-hook-form";

function RegistrationSubgroup({ disabled, event, registration }) {
  const { control, watch, formState: { errors } } = useFormContext();
  const groupId = watch("group");
  const registeredSubgroupId = registration.subgroup?.id || "";
  const { groups = [] } = event;
  const { subgroups = [] } = groups.find((x) => x.id === groupId) || {};

  if (subgroups.length === 0) return null;

  return (
    <Select
      control={control}
      disabled={disabled}
      name="subgroup"
      label="Subgroup"
      rules={{
        required: "Required",
      }}
      error={errors.subgroup}
      options={subgroups.map(({ registrationCount, capacity, name, id }) => {
        const full = registrationCount >= capacity ? " (Full)" : "";
        const alreadyRegistered =
          registeredSubgroupId === id ? " (Registered)" : "";
        return {
          disabled: !alreadyRegistered && registrationCount >= capacity,
          key: id,
          text: name + full + alreadyRegistered,
          value: id,
        };
      })}
    />
  );
}

export default RegistrationSubgroup;
