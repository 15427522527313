import React from "react";
import { Menu } from "semantic-ui-react";

function EventFormNavigationMenu({
  eventFormSections,
  currentSection,
  onMenuItemClick,
}) {
  const menuItems = eventFormSections.map((section) => {
    return (
      <Menu.Item
        active={section.id === currentSection}
        href="#"
        key={section.id}
        onClick={(e) => onMenuItemClick(e, section.id)}
      >
        {section.title}
      </Menu.Item>
    );
  });
  return (
    <nav>
      <Menu vertical>{menuItems}</Menu>
    </nav>
  );
}

export default EventFormNavigationMenu;
