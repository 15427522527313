import React from "react";
import { useLoadedUser } from "@sempra-event-registration/common";
import UserMenu from "../UserMenu/UserMenu";
import styles from "./Header.module.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

function Header() {
  const user = useLoadedUser();
  return (
    <header className={styles.header}>
      <nav>
        <div>
          <Logo />
        </div>
        <div>{user && <UserMenu user={user} />}</div>
      </nav>
      <div className={styles.headerLinks}>
        <Link to="/events" className={styles.headerLinksEvents}>
          <Icon name="th" />
          Events
        </Link>
        {user?.roles.find((role) => role.endsWith("admin_supervisor")) && (
          <Link to="/admins" className={styles.headerLinksAdmin}>
            Admins
          </Link>
        )}
      </div>
    </header>
  );
}

export default Header;
