import { endOfYesterday, startOfToday } from "date-fns";
import queryString from "query-string";
import {
  isBefore,
  isAfter,
  startOfDay,
  endOfDay,
  format,
  parse,
} from "date-fns";
import {
  comparing,
  dateComparator,
  isBlank,
  nullsFirst,
  reverse,
} from "@sempra-event-registration/common";

const dateFormat = "MM/dd/yyyy";

export function filtersToQuery(filters, user) {
  const query = {};
  if (!isBlank(filters.search)) {
    query.search = filters.search;
  }
  if (filters.onlyMyEvents) {
    query.by = user.id;
  } else {
    query.by = null;
  }
  if (filters.onlyPastEvents) {
    query.to = endOfYesterday();
  } else {
    query.from = startOfToday();
  }
  return query;
}

export function queryToFilters(query, user) {
  return {
    search: query.search || "",
    onlyMyEvents: query.by === user.id,
    onlyPastEvents: query.to != null,
  };
}

export function searchToQuery(search) {
  const { q, by, from, to } = queryString.parse(search.slice(1));
  const query = {};
  if (!isBlank(q)) {
    query.search = q;
  }
  if (!isBlank(by)) {
    query.by = by;
  }
  if (!isBlank(from)) {
    query.from = startOfDay(parse(from, dateFormat, new Date()));
  }
  if (!isBlank(to)) {
    query.to = endOfDay(parse(to, dateFormat, new Date()));
  }
  return query;
}

export function queryToSearch(query) {
  const { search, by, from, to } = query;
  const values = {};
  if (!isBlank(search)) {
    values.q = search;
  }
  if (by != null) {
    values.by = by;
  }
  if (from != null) {
    values.from = format(from, dateFormat);
  }
  if (to != null) {
    values.to = format(to, dateFormat);
  }
  return "?" + queryString.stringify(values);
}

// TODO apply search here instead of using table as it is a separate concern?
export function queryToFilter(query) {
  return (event) =>
    (query.by == null || event.createdBy?.id === query.by) &&
    (query.to == null || isBefore(event.endsAt, query.to)) &&
    (query.from == null ||
      event.startsAt == null ||
      isAfter(event.startsAt, query.from));
}

export function queryToComparator(query) {
  const baseComparator = comparing(
    nullsFirst(dateComparator),
    (x) => x.startsAt,
  );
  return query.from != null ? baseComparator : reverse(baseComparator);
}
