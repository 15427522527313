import { selectError } from "../../../../../../../../../shared/form/support";
import React from "react";
import { email, requiredNotBlank } from "@sempra-event-registration/common";
import { FormField } from "../../../../../../../../../shared/semantic-form";
import InternalResourceTypeahead from "../../../InternalResourceTypeahead/InternalResourceTypeahead";
import { Controller } from "react-hook-form";

function field(selector = (value) => value, validator) {
  return (value) => validator(selector(value));
}

function RegistrationResource({
  control,
  disabled,
  errors,
  loading,
  invitation,
  emailAvailabilityValidator,
  emailInAudienceValidator,
  onChange,
  onSearchChange,
}) {
  return (
    <Controller
      control={control}
      name="emailOrResource"
      rules={{
        validate: {
          required: field((x) => x.email, requiredNotBlank()),
          email: field((x) => x.email, email()),
          emailAvailability: field((x) => x.email, emailAvailabilityValidator),
          emailInAudience: field((x) => x.email, emailInAudienceValidator),
        },
      }}
      render={({
        field: { value, onChange: onChangeInternal, ref },
        ...rest
      }) => (
        <FormField
          {...{
            disabled,
            label: invitation ? "Invitee" : "Registrant",
            error: selectError(() => errors.emailOrResource),
          }}
        >
          <InternalResourceTypeahead
            placeholder="Find someone by name or email"
            icon={disabled ? null : "search"}
            loading={loading}
            disabled={disabled}
            search={value.email}
            onSearchChange={(search) => {
              onChangeInternal({ email: search });
              onSearchChange(search);
            }}
            onChange={(value) => {
              // clears validation error sooner
              onChangeInternal({ email: value.email });
              onChange(value);
            }}
            {...rest}
          />
        </FormField>
      )}
    />
  );
}

export default RegistrationResource;
