import React from "react";
import { Input } from "../../../../../../../../../shared/react-hook-form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import { selectError } from "../../../../../../../../../shared/form";
import classes from "./EventGroup.module.css";

function EventGroup({ index, field, namePrefix, errors, ...fieldProps }) {
  return (
    <span className={classes.grid}>
      <Input
        name={namePrefix + "name"}
        placeholder="Group Name"
        label={`Group ${index + 1}`}
        error={selectError(() => errors.name)}
        rules={{
          validate: requiredNotBlank("Required"),
        }}
        defaultValue={field.name || ""}
        {...fieldProps}
      />
    </span>
  );
}

export default EventGroup;
