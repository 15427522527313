import React from "react";

// Copied from shared-it-appmod-ui "ATMBrandLogo"
// TODO move this SVG to the public dir
function Logo() {
  const color = "white";
  const style = {
    height: "31px",
    width: "auto",
    padding: "0 0.5rem",
  };

  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 352 72"
      id="svg2"
    >
      <defs>
        <clipPath id="b">
          <rect width="352" height="72" />
        </clipPath>
      </defs>
      <g id="a">
        <g transform="translate(-0.556 -0.65)">
          <path
            id="b"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="M227.14,57.51h9V44.43h7.59c10.53,0,17.34-5.9,17.34-15v-.12c0-9-6.44-14.79-16.41-14.79H227.14Zm16.91-21h-7.9V22.55h7.71c5.07,0,8.09,2.58,8.09,6.91v.12c.05,4.13-3.18,6.9-7.9,6.9Zm50.89,21h10.5L294.88,42.09l.36-.14c5.9-2.19,9.15-6.89,9.15-13.24v-.13a13.43,13.43,0,0,0-3.65-9.76c-2.84-2.84-7.16-4.34-12.5-4.34H268.59v43h9V43.56h8Zm-7.33-21.84h-10V22.55h9.84c5,0,7.83,2.38,7.83,6.53v.13c-.02,3.99-2.95,6.46-7.67,6.46Zm54.94,21.84h9.38L333.49,14.17H325L306.59,57.51h9.13l4-9.78h18.85Zm-7.17-17.66H322.9l6.24-15.23Zm-246.17,12a27.67,27.67,0,0,0,18.3,6.38c9.69,0,15.72-5.12,15.72-13.36v-.12c0-7.71-5.21-10.61-13.61-12.8C102,30.05,100,29.08,100,26v-.13c0-2.11,1.83-4.23,5.91-4.23a20,20,0,0,1,11.27,3.71l4.65-6.55a24.9,24.9,0,0,0-15.8-5c-8.81,0-15,5.34-15,13v.13c0,8.74,5.9,10.92,14.24,13.05,7.07,1.81,9,3.07,9,5.83v.13c0,2.76-2.59,4.54-6.59,4.54a20.32,20.32,0,0,1-13.19-4.84Zm42.36,5.62h32.62v-7.9H140.51V39.82h20.57V31.87H140.51V22.43h23.37V14.48H131.57Zm75.91,0h8.94v-43h-9.88L194.91,33.2,183.28,14.48h-9.89v43h8.82v-29l12.57,19.08,12.7-19.27Z"
          />
          <path
            id="b"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="M38.25,9.91c2.74,0,5,1.95,5,4.36s-2.23,4.35-5,4.35-5-1.95-5-4.35S35.51,9.91,38.25,9.91Zm29.32,8.86a10.55,10.55,0,0,0,.88-2.33c0-.07.07-.33,0-.42a.24.24,0,0,0-.33,0C47.91,31.25,27.56,11.79,29.59,24.4c.26,1.63,1.86,4.56,3.8,7.4a.26.26,0,0,1,0,.33.64.64,0,0,1-.28.18c-10.54,5.58-17.52,14-21.79,20.75A30.05,30.05,0,0,1,58,15.5l.06.08.09-.05a29.21,29.21,0,0,0,4.93-3l.12-.09-.1-.11A36,36,0,0,0,8.1,58.7c-1.32,2.62-2,4.49-2.27,5.05C4.92,66,5.71,66.66,6,66.8c.65.26,1.6,0,2.56-1.27C15.3,57,33.43,36,42.41,42c5.09,3.44,4.93,7.3,1.1,10.84a37.44,37.44,0,0,1-7.57,4.85c-3.49,1.65-5.37,2-5.09,3.21s1.74,1.14,7.61-.39c6.75-1.75,21.43-6.8,15.3-19.06-3-6-13.14-10.87-13.14-11s.3-.1.3-.1c11.9-.89,18.64-3.8,22.46-6.74A30,30,0,0,1,19.15,60.88L19,60.8l-.08.12c-1,1.6-2,3.23-2.92,4.85l-.06.12.11.07A36,36,0,0,0,67.57,18.77Z"
          />
          <path id="b" d="M342.77,15.07H341v-.9h4.49v.9h-1.76v4.62h-1Z" />
          <path
            id="b"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="M346.51,14.17h1l1.68,2.61,1.68-2.61h1v5.52h-1v-4l-1.74,2.61h0l-1.73-2.59v3.94h-.95Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
