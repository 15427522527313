import { Store } from "react-notifications-component";

// Props documentation:
// container: ["top-left", "top-right", "top-center", "center", "bottom-left", "bottom-right", "bottom-center"]
// type: ["success", "danger", "info", "default", "warning"];
// insert: ["top", bottom"] ------> This is where the next toast gets inserted, below or above the stack.

// Note: function to remove toast is returned and can be called programmatically somewhere else.
// BUT DOES NOT NEED TO BE CALLED!
const toastNotification = function toastNotification({
  title,
  message,
  type = "success",
  insert = "top",
  container = "bottom-right",
}) {
  const id = Store.addNotification({
    title,
    message,
    type,
    insert,
    container,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      pauseOnHover: true,
      onScreen: true,
      showIcon: true,
    },
  });
  return removeToast(id);
};

const removeToast = (id) => () => Store.removeNotification(id);

export default toastNotification;
