import React from "react";
import { Input as InputComponent } from "../../../form";
import { Controller } from "react-hook-form";

function Input({ control, name, defaultValue, rules, ...rest }) {
  return (
    <Controller
      {...{ name, control, defaultValue, rules }}
      render={({ field, ...props }) => (
        <InputComponent {...props} {...field} {...rest} />
      )}
    />
  );
}

export default Input;
