import { DEFAULT_IMAGE } from "../constants";

export const eventTypes = ["sdge_external", "internal", "sdge_employee"];

export const eventTypeNamesByType = {
  sdge_external: "SDG&E External",
  internal: "Internal",
  sdge_employee: "SDG&E Communication",
};

const audienceFactoryByEventType = {
  sdge_external: () => ({
    userType: {
      external: true,
      internal: false,
    },
    company: {
      sempra: true,
      sdge: true,
      scg: true,
    },
    resourceType: {
      employee: true,
      contractor: true,
    },
  }),
  internal: () => ({
    userType: {
      internal: true,
      external: false,
    },
    company: {
      sempra: true,
      sdge: true,
      scg: true,
    },
    resourceType: {
      employee: true,
      contractor: true,
    },
  }),
  sdge_employee: () => ({
    userType: {
      external: false,
      internal: true,
    },
    company: {
      sempra: false,
      sdge: true,
      scg: false,
    },
    resourceType: {
      employee: true,
      contractor: false,
    },
  }),
};

const defaultCompanyByEventType = {
  sdge_external: "sdge",
  sdge_employee: "sdge",
};

// Default values for new event. Please edit defaultValues here for event form.
// If you're getting '[form field] is missing in the 'defaultValue' prop of either its...'
export function createEvent(type, user) {
  return {
    type,
    audience: audienceFactoryByEventType[type](),
    company: defaultCompanyByEventType[type] || user.company || "sempra",
    askIfFirstEvent: false,
    askIfRepresentedByUnion: false,
    askForCompanyContact: false,
    createdBy: user.id,
    createdAt: new Date(),
    status: "draft",
    image: DEFAULT_IMAGE,
    invitationOnly: false,
    securityGroups: [],
    registrationDeadline: null,
    notifyContact: false,
    askForReleaseWaiver: false,
    name: "",
    endTime: null,
    startTime: null,
    date: null,
    summary: "",
    contact: { email: "", name: "", phone: "" },
    location: {
      address: {
        city: "",
        line1: "",
        line2: "",
        state: "",
        zipCode: "",
      },
      locationName: "",
      capacity: "",
    },
  };
}
