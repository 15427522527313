import React from "react";
import { Checkbox, Input } from "../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../shared/form/support";
import { Controller, useFormContext } from "react-hook-form";
import { totalSubgroupCapacities } from "../../../../support/eventForms";
import ReactNumberFormat from "react-number-format";
import { FormField } from "../../../../../../../shared/semantic-form";
import classes from "./EventLocationCapacity.module.css";

function EventLocationCapacity({ control, disabled, errors }) {
  const { watch, getValues } = useFormContext();
  const groups = watch("groups", getValues().groups || []);
  const useSubgroupCapacities = watch("useSubgroupCapacities");
  const isVirtualEvent = watch("isVirtualEvent");
  const hasSubgroups = groups.some((x) => (x.subgroups || []).length > 0);

  return (
    <div>
      {useSubgroupCapacities ? (
        <>
          <Controller
            control={control}
            name="capacity"
            render={({ value = "", ...props }) => (
              <input type="hidden" {...{ value, ...props }} />
            )}
          />
          <FormField label="Capacity" disabled>
            <ReactNumberFormat
              thousandSeparator={true}
              disabled
              value={totalSubgroupCapacities(groups)}
            />
          </FormField>
        </>
      ) : (
        <Input
          control={control}
          disabled={useSubgroupCapacities}
          name="capacity"
          label="Capacity"
          type="number"
          allowNegative={false}
          decimalScale={0}
          rules={{
            required: "Required",
            min: {
              value: 1,
              message: "Please enter a number greater than zero",
            },
            max: isVirtualEvent
              ? {
                  value: 250,
                  message:
                    "Max capacity for virtual events is 250. Please enter a capacity of 250 or less.",
                }
              : {},
          }}
          error={selectError(() => errors.capacity)}
        />
      )}
      {hasSubgroups && (
        <Checkbox
          {...{ control, disabled }}
          name="useSubgroupCapacities"
          label="Use total subgroup capacities"
          className={classes.checkbox}
        />
      )}
    </div>
  );
}

export default EventLocationCapacity;
