import React from "react";
import { Checkbox } from "../../../../../../../shared/react-hook-form";

function EventNotifyContact({ control, disabled }) {
  return (
    <Checkbox
      control={control}
      name="notifyContact"
      label="Notify contact of registration changes"
      disabled={disabled}
    />
  );
}

export default EventNotifyContact;
