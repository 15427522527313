import React from "react";
import { Input } from "../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../shared/form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import classes from "./EventRoom.module.css";

function EventRoom({ index, field, namePrefix, errors, ...fieldProps }) {
  return (
    <span className={classes.grid}>
      <Input
        name={namePrefix + "name"}
        label={"Room " + (index + 1)}
        error={selectError(() => errors.name)}
        rules={{
          validate: requiredNotBlank("Required"),
        }}
        defaultValue={field.name || ""}
        {...fieldProps}
      />
      <Input
        name={namePrefix + "capacity"}
        label="Max Capacity"
        type="number"
        allowNegative={false}
        decimalScale={0}
        rules={{
          required: "Required",
          min: {
            value: 1,
            message: "Please enter a number greater than zero",
          },
        }}
        error={selectError(() => errors.capacity)}
        defaultValue={field.capacity || ""}
        {...fieldProps}
      />
    </span>
  );
}

export default EventRoom;
