const authListeners = (() => {
  let listeners = [];

  window.gm_authFailure = (...args) => {
    listeners.forEach((listener) => listener(...args));
  };

  return {
    addListener: (listener) => {
      listeners.push(listener);
    },
    removeListener: (listenerToRemove) => {
      listeners = listeners.filter((listener) => listener !== listenerToRemove);
    },
  };
})();

export const addGoogleMapsAuthenticationErrorListener =
  authListeners.addListener;
export const removeGoogleMapsAuthenticationErrorListener =
  authListeners.removeListener;
