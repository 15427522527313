import React, { useState, useEffect } from "react";
import {
  companyShortNameByCompanyCode,
  isBlank,
  loadedResource,
  loadingResource,
  functions,
} from "@sempra-event-registration/common";
import { Search } from "semantic-ui-react";
import { httpsCallable } from "firebase/functions";
import classes from "./InternalResourceTypeahead.module.css";

const findUsers = httpsCallable(functions, "findUsers");

async function findUsersBySearchTerm(searchString) {
  return findUsers({ searchString });
}

function internalResourceToOption(resource) {
  // Semantic Search components expects each Search.Result to have a 'title' property (required)
  // So we are using 'title' instead of something more meaningful like 'displayName'
  // https://react.semantic-ui.com/modules/search/
  return {
    key: resource.id,
    title: `${resource.firstName} ${resource.lastName}`,
    description: `${
      companyShortNameByCompanyCode[resource.company || "sempra"]
    }, ${resource.email}`,
    value: resource,
  };
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

function InternalResourceTypeahead({
  search = "",
  onChange = () => {},
  onSearchChange = () => {},
  limit = 5,
  debounce = 200,
  className,
  ...rest
}) {
  const [results, setResults] = useState(loadedResource([]));
  const debouncedSearch = useDebounce(search.trim(), debounce);
  const loading = results.status === "loading";
  const noResultsMessage = loading
    ? `Searching...`
    : isBlank(search)
      ? "Enter someone's name or email"
      : `No resources matching "${search}"`;

  useEffect(() => {
    if (debouncedSearch) {
      if (isBlank(debouncedSearch)) {
        setResults(loadedResource([]));
      } else {
        setResults(loadingResource());
        findUsersBySearchTerm(debouncedSearch)
          .then((response) => response.data || [])
          .then((resources) => {
            setResults(
              loadedResource(
                resources.slice(0, limit).map(internalResourceToOption),
              ),
            );
          });
      }
    } else {
      setResults([]);
    }
  }, [debouncedSearch, limit]);

  function onSearchChangeInternal(event, data) {
    onSearchChange(data.value);
  }

  function onResultSelect(e, data) {
    onChange(data.result.value);
  }

  return (
    <Search
      className={`${className || ""} ${classes.input}`}
      fluid
      {...{
        icon: null,
        value: search,
        results: results.value || [],
        loading,
        onSearchChange: onSearchChangeInternal,
        onResultSelect,
        noResultsMessage,
      }}
      {...rest}
    />
  );
}

export default InternalResourceTypeahead;
