import React from "react";
import FormModal from "../../../event/page/EventPage/page/EventRegistrationPage/component/FormModal/FormModal";
import AdminFormFields from "../AdminsFormFields/AdminsFormfFields";
import { isBlank } from "@sempra-event-registration/common";

function AdminsModal({
  adminUsers,
  adminUser,
  setAdminUser,
  open,
  isNew,
  onClose,
  onAccept,
}) {
  const { firstName, lastName, email, id } = adminUser;
  const displayName =
    !isBlank(firstName) && !isBlank(lastName)
      ? `${firstName} ${lastName}`
      : email || id;

  const modalHeader = isNew ? "Add Event Admin" : displayName;

  return (
    <FormModal
      heading={modalHeader}
      accept={() => (isNew ? "Add" : "Save")}
      defaultValues={adminUser}
      open={open}
      onClose={onClose}
      onAccept={onAccept}
    >
      <AdminFormFields
        adminUsers={adminUsers}
        setAdminUser={setAdminUser}
        isNew={isNew}
      />
    </FormModal>
  );
}

export default AdminsModal;
