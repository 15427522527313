import { timeIsBefore } from "@sempra-event-registration/common";
import { format } from "date-fns";

/**
 * Validate the selected start or end time for a workshop falls within the event's start time and end time.
 *
 * @param time {Date} The time of the workshop (either the start time or the end time)
 * @param eventTimes {Object} An object containing the start and end times of the event
 * @param startOrEnd {string} A string indicating which workshop time being evaluated.  Possible values are "start" or "end"
 * @returns {null|string} null if validation is successful; otherwise a string indicating the error condition
 */
export function validateWithinTimeBoundaries(time, eventTimes, startOrEnd) {
  const { startTime, endTime } = eventTimes;
  if (!startTime || !endTime) return null;
  const endTimeIsBeforeEventStartTime = timeIsBefore(
    startTime,
    time,
    `The ${startOrEnd} time must occur after event start time of ${format(
      startTime,
      "h:mm a"
    )}`
  );

  if (endTimeIsBeforeEventStartTime) {
    return endTimeIsBeforeEventStartTime;
  }

  const endTimeIsAfterEventEndTime = timeIsBefore(
    time,
    endTime,
    `The ${startOrEnd} time must occur before event end time of ${format(
      endTime,
      "h:mm a"
    )}`
  );
  if (endTimeIsAfterEventEndTime) {
    return endTimeIsAfterEventEndTime;
  }
  return null;
}
