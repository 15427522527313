import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import {
  UserProvider,
  RemoteConfigProvider,
  useRemoteConfigFlags,
  ScrollToTop,
} from "@sempra-event-registration/common";
import ProtectedRoute from "./shared/auth/component/ProtectedRoute/ProtectedRoute";
import EventPage from "./event/page/EventPage/EventPage";
import EventsPage from "./event/page/EventsPage/EventsPage";
import Header from "./shared/ui/component/Header/Header";
import { Loader } from "./shared/ui";
import SignedOut from "./shared/auth/component/SignedOut";
import { PrintArea, PrintProvider } from "./shared/print";
import AdminsPage from "./admins/page/AdminsPage";
import classes from "./App.module.css";

function AppProviders({ children }) {
  return (
    <RemoteConfigProvider>
      <UserProvider>
        <PrintProvider>{children}</PrintProvider>
      </UserProvider>
    </RemoteConfigProvider>
  );
}

function AppContent() {
  const flags = useRemoteConfigFlags();
  if (flags.status !== "loaded") return <Loader />;

  return (
    <>
      <ScrollToTop />
      <Header />
      <main className={classes.main}>
        <Routes>
          <Route
            path="/admins"
            element={
              <ProtectedRoute
                hasAnyPermission={[
                  "sdge_external_event_admin_write",
                  "internal_event_admin_write",
                  "sdge_employee_event_admin_write",
                ]}
              >
                <AdminsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute
                hasAnyPermission={[
                  "sdge_external_event_write",
                  "internal_event_write",
                  "sdge_employee_event_write",
                ]}
              />
            }
          >
            <Route path="/events" element={<EventsPage />} />
            <Route path="/events/:eventId/*" element={<EventPage />} />
          </Route>
          <Route
            exact
            path="/*"
            element={<Navigate to={`/events${window.location.search}`} />}
          />
        </Routes>
      </main>
      <footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* This route was added here as anything lower would load the userProvider, and that authenticates a user, this prevents that from happening.  */}
        <Route exact path="/signout" element={<SignedOut />}></Route>
        <Route
          path="/*"
          element={
            <>
              <AppProviders>
                <ReactNotifications />
                <AppContent />

                {/* only displayed when printing */}
                <PrintArea />
              </AppProviders>
            </>
          }
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
