import React from "react";
import classes from "./InfoButton.module.css";
import { Popup, Icon } from "semantic-ui-react";

function InfoButton({ content }) {
  return (
    <Popup
      trigger={<Icon className={classes.infoButton} name="info circle" />}
      content={content}
      position="top center"
      on="click"
    />
  );
}

export default InfoButton;
