import { isBlank } from "@sempra-event-registration/common";

export function csvParser({
  delimiter = ",",
  csvHasHeaders = false,
  useCsvHeaders = false,
  headers = [],
}) {
  return (textCsv) => {
    let rows = csvToArray(textCsv, delimiter);

    if (csvHasHeaders) {
      const headerRow = rows[0];
      if (useCsvHeaders) {
        headers = headerRow;
      }
      rows = rows.slice(1);
    }

    if (headers.length === 0) {
      return rows;
    }

    return rows.map((values) =>
      headers.reduce((row, header, index) => {
        const value = values[index];
        if (!isBlank(value)) {
          row[header] = value;
        }
        return row;
      }, {})
    );
  };
}

// Sourced from https://stackoverflow.com/questions/36288375/how-to-parse-csv-data-that-contains-newlines-in-field-using-javascript
// was not able to include the seemingly leading csvtojson library
/**
 * csvToArray parses any String of Data including '\r' '\n' characters,
 * and returns an array with the rows of data.
 * @param {String} textCsv the CSV string you need to parse
 * @param {String} delimiter the delimeter used to separate fields of data
 * @returns {Array} rows The rows of CSV where first row are column headers
 */
function csvToArray(textCsv, delimiter = ",") {
  // regular expression to parse the CSV values.
  const pattern = new RegExp(
    // Delimiters:
    "(\\" +
      delimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      delimiter +
      "\\r\\n]*))",
    "gi"
  );

  const rows = [[]];
  let matches;
  // Loop until we no longer find a regular expression match
  // This method ignores rows with the incorrect formatting
  while ((matches = pattern.exec(textCsv)) != null) {
    const matchedDelimiter = matches[1]; // Get the matched delimiter
    // Check if the delimiter has a length (and is not the start of string)
    // and if it matches field delimiter. If not, it is a row delimiter.
    if (matchedDelimiter.length && matchedDelimiter !== delimiter) {
      // Since this is a new row of data, add an empty row to the array.
      rows.push([]);
    }

    // Once we have eliminated the delimiter, check to see
    // what kind of value was captured (quoted or unquoted):
    const value = matches[2]
      ? // found quoted value. unescape any double quotes.
        matches[2].replace(new RegExp('""', "g"), '"')
      : // found a non-quoted value
        matches[3];

    // Now that we have our value string, let's add
    // it to the data array.
    rows[rows.length - 1].push(value);
    //check if 3 values have been pushed into array index
  }
  // keep the row if it has any non-blank values
  // if all the row values are blank, discard the row
  return rows.filter((row) => row.some((cellValue) => !isBlank(cellValue)));
}
