import {
  isBlank,
  timeRangesIntersect,
} from "@sempra-event-registration/common";

export function groupBy(values, keyProvider) {
  return values.reduce((groups, value) => {
    const key = keyProvider(value);
    const group = groups.find((x) => x.key === key);
    if (group != null) {
      group.values.push(value);
    } else {
      groups.push({
        key,
        values: [value],
      });
    }
    return groups;
  }, []);
}

// Finds first room with overlapping workshops and first workshop pair in overlap
export function noWorkshopTimeOverlapPerRoom() {
  return (workshops) => {
    const workshopRoomGroups = groupBy(workshops, (x) => x.room.id);
    for (let i = 0; i < workshopRoomGroups.length; i++) {
      const group = workshopRoomGroups[i];
      for (let j = 0; j < group.values.length; j++) {
        const workshop = group.values[j];
        for (let k = j + 1; k < group.values.length; k++) {
          const otherWorkshop = group.values[k];
          if (
            timeRangesIntersect(
              workshop.startTime,
              workshop.endTime,
              otherWorkshop.startTime,
              otherWorkshop.endTime
            )
          ) {
            const roomName = workshop.room.name;
            return isBlank(roomName)
              ? "Workshops must not overlap"
              : `Workshops are overlapping in room ${roomName}`;
          }
        }
      }
    }
  };
}
