import React from "react";
import { Input } from "../../../../../../../shared/react-hook-form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import { selectError } from "../../../../../../../shared/form/support";
import { defaultNameMaxLength } from "../../../../support/eventFormDefaults";

const maxLength = defaultNameMaxLength;

function EventSummary({ control, disabled, errors }) {
  return (
    <Input
      label="Summary"
      name="summary"
      control={control}
      disabled={disabled}
      error={selectError(() => errors.summary)}
      rules={{
        validate: requiredNotBlank("Required"),
        maxLength: {
          value: maxLength,
          message: `Cannot exceed ${maxLength} characters`,
        },
      }}
    />
  );
}

export default EventSummary;
