function formErrorToSemanticError(value) {
  return {
    content: value.message,
    pointing: "above",
  };
}

export function selectError(selector) {
  const selection = selector();
  return selection != null ? formErrorToSemanticError(selection) : null;
}
