import { useRemoteConfig } from "./useRemoteConfig";
import { map } from "../../resource";
import { getAll } from "firebase/remote-config";

/**
 * Returns a subset of the remote config where:
 * 1. Entries set to "true" or "enabled" are present and true
 * 2. Entries set to anything else are absent
 */
export function useRemoteConfigFlags() {
  const resource = useRemoteConfig();
  return map(resource, (remoteConfig) =>
    Object.entries(getAll(remoteConfig)).reduce((map, [key, value]) => {
      if (value.asBoolean()) {
        map[key] = true;
      }
      return map;
    }, {}),
  );
}
