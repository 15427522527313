import React, { useEffect } from "react";
import {
  requiredNotBlank,
  timeIsBefore,
  doesNotOverlapOtherDateRanges,
} from "@sempra-event-registration/common";
import {
  Input,
  TimeInput,
} from "../../../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../../../shared/form";
import classes from "./EventMeal.module.css";
import { useFormContext } from "react-hook-form";
import { addMinutes, subMinutes } from "date-fns";
import {
  defaultEventMealDurationInMinutes,
  defaultEventMaxTime,
  defaultEventMinTime,
  defaultEventMealNameMaxLength,
} from "../../../../../../support/eventFormDefaults";
import { validateWithinTimeBoundaries } from "../../../../../../support/validateWithinTimeBoundaries";

function Name({ index, field, namePrefix, errors, ...fieldProps }) {
  return (
    <Input
      name={namePrefix + "name"}
      label={`Meal ${index + 1}`}
      error={selectError(() => errors.name)}
      rules={{
        validate: requiredNotBlank("Required"),
        maxLength: {
          value: defaultEventMealNameMaxLength,
          message: `Cannot exceed ${defaultEventMealNameMaxLength} characters`,
        },
      }}
      defaultValue={field.name}
      {...fieldProps}
    />
  );
}

function StartTime({
  field,
  namePrefix,
  errors,
  eventData,
  index,
  control,
  disabled,
}) {
  const { watch, clearErrors, setValue, trigger } = useFormContext();
  const endTime = watch(namePrefix + "endTime");
  const startTime = watch(namePrefix + "startTime");

  useEffect(() => {
    if (!startTime) return;
    trigger(namePrefix + "startTime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  useEffect(() => {
    if (eventData.startTime > startTime || eventData.endTime < startTime)
      setValue(namePrefix + "startTime", eventData.startTime, {
        shouldValidate: true,
        shouldDirty: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData.startTime, eventData.endTime]);

  return (
    <TimeInput
      name={namePrefix + "startTime"}
      label="Start Time"
      error={selectError(() => errors.startTime)}
      rules={{
        required: "Required",
        validate: (startsAt) => {
          clearErrors(namePrefix + "startTime");
          clearErrors(namePrefix + "endTime");

          const startTimeIsBeforeEndTimeError = timeIsBefore(
            startsAt,
            endTime,
            "Start time must occur before end time",
          );
          if (startTimeIsBeforeEndTimeError) {
            return startTimeIsBeforeEndTimeError;
          }
          const mealTimesOverlapOtherMealTimes = doesNotOverlapOtherDateRanges(
            { startTime: startsAt, endTime },
            [
              ...eventData.meals.slice(0, index),
              ...eventData.meals.slice(index + 1),
            ],
          );
          if (mealTimesOverlapOtherMealTimes) {
            return mealTimesOverlapOtherMealTimes;
          }

          return validateWithinTimeBoundaries(startsAt, eventData, "start");
        },
      }}
      defaultValue={field.startTime}
      minTime={eventData.startTime}
      maxTime={subMinutes(
        endTime != null ? endTime : defaultEventMaxTime,
        defaultEventMealDurationInMinutes,
      )}
      {...{ control, disabled, index }}
    />
  );
}

function EndTime({
  field,
  namePrefix,
  errors,
  eventData,
  control,
  disabled,
  index,
}) {
  const { watch, clearErrors, setValue } = useFormContext();
  const startTime = watch(namePrefix + "startTime");
  const endTime = watch(namePrefix + "endTime");

  useEffect(() => {
    if (eventData.startTime > startTime || eventData.endTime < endTime) {
      setValue(namePrefix + "endTime", addMinutes(eventData.startTime, 30), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData.startTime, eventData.endTime]);

  return (
    <TimeInput
      name={namePrefix + "endTime"}
      label="End Time"
      error={selectError(() => errors.endTime)}
      rules={{
        required: "Required",
        validate: (endsAt) => {
          clearErrors(namePrefix + "startTime");
          clearErrors(namePrefix + "endTime");

          const mealTimesIntersect = doesNotOverlapOtherDateRanges(
            { startTime, endTime: endsAt },
            [
              ...eventData.meals.slice(0, index),
              ...eventData.meals.slice(index + 1),
            ],
          );
          if (mealTimesIntersect) {
            return mealTimesIntersect;
          }

          return timeIsBefore(
            startTime,
            endsAt,
            "End time must occur after start time",
          );
        },
      }}
      defaultValue={field.endTime}
      minTime={addMinutes(
        startTime != null ? startTime : defaultEventMinTime,
        defaultEventMealDurationInMinutes,
      )}
      maxTime={eventData.endTime}
      {...{ control, disabled, index }}
    />
  );
}

function EventMeal(props) {
  const { watch } = useFormContext();
  const keys = ["startTime", "endTime", "meals"];
  const values = watch(keys);
  const eventData = keys.reduce((acc, key, index) => {
    acc[key] = values[index];
    return acc;
  }, {});

  return (
    <span className={classes.grid}>
      <Name {...props} />
      <StartTime {...{ ...props, eventData }} />
      <EndTime {...{ ...props, eventData }} />
    </span>
  );
}

export default EventMeal;
