import React from "react";
import { Outlet } from "react-router-dom";
import { useInternalUser } from "@sempra-event-registration/common";
import { Loader } from "../../../ui";
import AccessDenied from "../AccessDenied/AccessDenied";

function toArray(valueOrValues) {
  if (valueOrValues == null) {
    return [];
  }
  return Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues];
}

function intersects(a = [], b = []) {
  return a.some((x) => b.some((y) => x === y));
}

// unable to extract to common
// rollupjs error when exporting comps that rely on react-router-dom
function ProtectedRoute(props) {
  const { hasAnyPermission, children } = props;
  const userResource = useInternalUser();

  if (userResource.status === "empty" || userResource.status === "loading") {
    return <Loader />;
  }

  if (userResource.status === "error") {
    return <Loader />;
  }

  const user = userResource.value;

  const isAuthorized =
    hasAnyPermission == null ||
    intersects(user?.permissions, toArray(hasAnyPermission));

  if (!isAuthorized) {
    return <AccessDenied />;
  }
  return children ? children : <Outlet />;
}

export default ProtectedRoute;
