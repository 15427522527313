import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import { scrollToFirstError } from "../../../../../../../../../shared/form";

function SubmitButton({ onClick, children }) {
  const { trigger, getValues } = useFormContext();

  async function validateThenSubmit() {
    const valid = await trigger();
    if (valid) {
      onClick(getValues());
    } else {
      scrollToFirstError();
    }
  }

  return (
    <Button primary onClick={validateThenSubmit}>
      {children}
    </Button>
  );
}

function WorkshopModal({
  workshop,
  disabled,
  onAccept,
  onClose,
  children,
  ...rest
}) {
  return (
    <Modal
      size="small"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>
        {workshop.id != null ? workshop.name : "Add Workshop"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {disabled || (
          <SubmitButton onClick={onAccept}>
            {workshop.id != null ? "Save" : "Add"}
          </SubmitButton>
        )}
        <Button
          onClick={() => {
            onClose();
          }}
        >
          {disabled ? "Close" : "Cancel"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default WorkshopModal;
