import React from "react";
import { addMinutes } from "date-fns";
import { useFormContext } from "react-hook-form";
import { FieldTree } from "../../../../../../../shared/react-hook-form";
import { defaultEventMealDurationInMinutes } from "../../../../support/eventFormDefaults";
import EventMeal from "./component/EventMeal/EventMeal";
import EventMealOption from "./component/EventMealOption/EventMealOption";

function EventMeals(fieldProps) {
  const { getValues } = useFormContext();

  const field = {
    name: "meals",
    label: "Meal",
    limit: 5,
    render: (props) => {
      return <EventMeal {...{ ...fieldProps, ...props }} />;
    },
    empty: () => {
      const { startTime } = getValues();

      return {
        name: "",
        startTime,
        endTime: startTime
          ? addMinutes(startTime, defaultEventMealDurationInMinutes)
          : undefined,
      };
    },
    childFields: [
      {
        name: "options",
        label: "Option",
        limit: 5,
        render: (props) => <EventMealOption {...{ ...fieldProps, ...props }} />,
        empty: () => ({ name: "", dietaryType: "" }),
      },
    ],
  };

  return <FieldTree {...{ field, ...fieldProps }} />;
}

export default EventMeals;
