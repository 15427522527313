import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { format } from "date-fns-tz";

function DeleteWorkshopModal({ workshop, onAccept, onClose, ...rest }) {
  const { name, startTime, room } = workshop;
  const formattedStartTime = format(startTime, "h:mm aa", {
    timeZone: "America/Los_Angeles",
  });

  return (
    <Modal
      size="small"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>Delete Workshop</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Are you sure you want to delete the{" "}
            <strong>
              {formattedStartTime} "{name}"
            </strong>{" "}
            in <strong>{room.name}</strong>?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            onAccept();
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteWorkshopModal;
